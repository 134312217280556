import { FilePdfOutlined } from "@ant-design/icons";
import { Button, Skeleton, Slider, Space, message } from "antd"; // Import Slider from Ant Design
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../App.css";
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
function FakturaC() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [fontSize, setFontSize] = useState(22); // Default font size
  const invoiceSectionRef = useRef(null);
  const { UserId } = useParams();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState({});

  useEffect(() => {

    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    console.log('Stored Permissions:', storedPermissions);

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }

    // Check if GuidesAzAdd permission is 0 and navigate if true
    if (storedPermissions && storedPermissions[0].GuidesAz === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fakturaUrl = `${CONN_KEY}Add.php?userid=${UserId}`;
        const fakturaResponse = await axios.get(fakturaUrl);
        setData(fakturaResponse.data);

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [UserId]);

  const handlePrint = () => {
    if (invoiceSectionRef.current) {
      const printContents = invoiceSectionRef.current.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();

      window.onafterprint = () => {
        // After printing is done, display a message
        message.info("Printing is complete. Refreshing the page...");

        // Wait for a short delay (adjust the delay as needed)
        setTimeout(() => {
          // Restore the original content
          document.body.innerHTML = originalContents;

          // Refresh the page automatically
          window.location.reload();
        }, 1000); // Refresh after 1 seconds (1000 milliseconds)
      };
    }
  };

  const onChange = (value) => {
    // Set the font size based on the Slider value
    setFontSize(value);
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Space className="user-ids">
            <Button className="ColorMain" onClick={handlePrint}>
              Print
            </Button>
            <Link to={`/PDF/${data.userid}`} target="_blank">
              <Button icon={<FilePdfOutlined />}>PDF</Button>
            </Link>
            <Slider
              className="adplgfsd"
              style={{ width: 350 }}
              min={10}
              max={40} // Adjust the max value as needed
              onChange={onChange}
              value={fontSize}
            />
          </Space>
          <div id="invoice-section" ref={invoiceSectionRef}>
            <div className="pages">
              <div className="CarVisit">
                <div className="ihd">
                  <div className="useridid">
                    <h3 className="isd" style={{ color: "red" }}>
                      V№{data.userid}
                    </h3>
                  </div>
                  <div className="ojdkde">
                    <div className="invoicx">
                      <img
                        className="imgPP light-effect"
                        src={`${CONN_KEY}3x4/${data.filePP}`}
                        alt={data.filePP}
                      />
                    </div>
                    <div className="jiudgf">
                      B{" "}
                      {data.vezife === "Driver" && (
                        <span className="spandirver">2</span>
                      )}
                    </div>
                  </div>
                  <div className="qr-name">
                    <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?data=https://senategroup.az/Beledciler/${data.userid}.pdf`}
                      alt={`QR code for ${data.userid}`}
                      className="qrcartd"
                    />
                    <div className="hsaddfa">
                      <div className="Namecart">
                        {/* Apply font size based on the fontSize state */}
                        <h2 className="fontsad" style={{ fontSize: fontSize }}>
                          {data.username}
                        </h2>
                      </div>
                      <div className="Namecartd">
                        <h2
                          className="fontsad"
                          style={{ fontSize: "clamp(14px, 15px, 100%)" }}
                        >
                          {data.surename}
                        </h2>
                      </div>
                      <div className="Namecart">
                        <h2 className="fondfg">{data.vezife}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Add the Slider component */}
          </div>
        </>
      )}
    </>
  );
}

export default FakturaC;
