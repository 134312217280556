import {
  CoffeeOutlined,
  FileProtectOutlined,
  FlagFilled,
  FlagOutlined,
  ReadOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Image, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import Logo from "../images/logo1.png";

const { Sider } = Layout;

const Siders = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [current, setCurrent] = useState("1");
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }
  }, []);

  const rootSubmenuKeys = ["guidesAz", "guidesGeo", "staff", "menu", "menuGeo", "uzv"];

  const items = [
    permissions.GuidesAz === 1 ? (
      <Menu.SubMenu key="guidesAz" icon={<FlagFilled />} title="Bələdçilər">
        <Menu.Item key="tables">
          <a href="/Tables">Bələdçilər-AZE</a>
        </Menu.Item>
        {permissions.GuidesAzAdd === 1 && (
          <Menu.Item key="add">
            <a href="/add">Yeni Bələdçi</a>
          </Menu.Item>
        )}
        <Menu.Item key="all">
          <a href="/all">Bütün Bələdçi-card</a>
        </Menu.Item>
      </Menu.SubMenu>
    ) : null,
    permissions.GuidesGeo === 1 ? (
      <Menu.SubMenu key="guidesGeo" icon={<FlagOutlined />} title="Guides">
        <Menu.Item key="tableGe">
          <a href="/tableGe">Bələdçilər-GE</a>
        </Menu.Item>
        <Menu.Item key="addGe">
          <a href="/addGe">Yeni Bələdçi-GE</a>
        </Menu.Item>
        <Menu.Item key="allGe">
          <a href="/all-Ge">All Cards</a>
        </Menu.Item>
      </Menu.SubMenu>
    ) : null,
    (permissions.StafGeo === 1 || permissions.StafAz === 1 || permissions.StafAzAdd === 1) ? (
      <Menu.SubMenu key="staff" icon={<UserOutlined />} title="Staff">
        {permissions.StafGeo === 1 && (
          <Menu.Item key="personalGe">
            <a href="/personal-ge">Staff-GE</a>
          </Menu.Item>
        )}
        {permissions.StafGeo === 1 && (
          <Menu.Item key="addPersonalGe">
            <a href="/add-personal-ge">Yeni Staff-GE</a>
          </Menu.Item>
        )}
        {permissions.StafGeo === 1 && (
          <Menu.Item key="all7taff">
            <a href="/All-Geo-Staff">All Staff-GE</a>
          </Menu.Item>
        )}
        {permissions.StafGeo === 1 && (
          <Menu.Item key="allStaff">
            <a href="/personal">Staff AZ</a>
          </Menu.Item>
        )}
        {permissions.StafAzAdd === 1 && (
          <Menu.Item key="addStaffAz">
            <a href="/addpersonal">Yeni Staff-AZ</a>
          </Menu.Item>
        )}
      </Menu.SubMenu>
    ) : null,
    (permissions.Menuaz === 1 || permissions.MenuPdfAZ === 1 || permissions.MenuazAdd === 1) ? (
      <Menu.SubMenu key="menu" icon={<CoffeeOutlined />} title="Menu">
        {permissions.MenuazAdd === 1 && (
          <Menu.Item key="menuAdd">
            <a href="/menuAdd">Yeni Menu</a>
          </Menu.Item>
        )}
        {permissions.Menuaz === 1 && (
          <Menu.Item key="menuTable">
            <a href="/Tablemenu">Menu Table</a>
          </Menu.Item>
        )}
        {permissions.MenuPdfAZ === 1 && (
          <Menu.Item key="menuPdf">
            <a href="/menupdf">Menu PDF</a>
          </Menu.Item>
        )}
      </Menu.SubMenu>
    ) : null,
    permissions.MenuGeo === 1 ? (
      <Menu.SubMenu key="menuGeo" icon={<ReadOutlined />} title="Menu-Geo">
        <Menu.Item key="menuAddGeo">
          <a href="/NewMenuGeo">Yeni Menu-Geo</a>
        </Menu.Item>
        <Menu.Item key="menuTableGeo">
          <a href="/MenugeoTable">Menu Table-Geo</a>
        </Menu.Item>
        <Menu.Item key="menuPdfGeo">
          <a href="/menugeo">Menu PDF-Geo</a>
        </Menu.Item>
      </Menu.SubMenu>
    ) : null,
    (permissions.Abone === 1 || permissions.AboneAdd === 1) ? (
      <Menu.SubMenu key="uzv" icon={<FileProtectOutlined />} title="Üzv">
        {permissions.AboneAdd === 1 && (
          <Menu.Item key="addGrils">
            <a href="/üzv">Yeni Üzv</a>
          </Menu.Item>
        )}
        {permissions.Abone === 1 && (
          <Menu.Item key="grilsTable">
            <a href="/üzvtable">Üzv Table</a>
          </Menu.Item>
        )}
      </Menu.SubMenu>
    ) : null,
    <Menu.SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
      <Menu.Item key="photo">
        <a href="/Webcam">Camera</a>
      </Menu.Item>
      <Menu.Item key="settings">
        <a href="/settings">Settings</a>
      </Menu.Item>
    </Menu.SubMenu>
  ].filter(Boolean); // Filter out null values from the array

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleImageClick = () => {
    window.location.href = "/Main";
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      className="sider"
    >
      <Image
        style={{
          padding: 20,
          width: "100%",
        }}
        className="logodfsaf cursor-pointer"
        preview={false}
        src={Logo}
        onClick={handleImageClick}
      />
      <Menu
        theme="dark"
        onClick={onClick}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenKeys={["sub1"]}
        selectedKeys={[current]}
        mode="inline"
      >
        {items.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </Menu>
    </Sider>
  );
};

export default Siders;
