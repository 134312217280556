import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Button, Layout, Space, Table, Tag } from "antd";
import axios from 'axios';
import MapM1ellips from './EllipsMap/MapEllipsM1';
import MapM2ellips from './EllipsMap/MapEllipsM2';
import MapM3ellips from './EllipsMap/MapEllipsM3';
const { Content } = Layout;

const CONN_KEY_R = process.env.REACT_APP_NOT_SECRET_CODE_RR;

function PrintpageEllips() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userLevel, setUserLevel] = useState('');
    const [invoiceSectionExists, setInvoiceSectionExists] = useState(false); // Add state to track if invoice section exists

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get(`${CONN_KEY_R}printrezervs.php`)
            .then(response => {
                if (Array.isArray(response.data.reservations)) {
                    // Desired selectedFlourId values and their order
                    const desiredFlourIds = ['E1L', 'E1V', 'E1R', 'E2L', 'E2R', 'E3L', 'E3M', 'E3R'];

                    // Get the current date in the format you want
                    const currentDate = new Date().toLocaleDateString('en-GB'); // Use 'en-GB' for dd/MM/yyyy format

                    // Filter reservations by the desired date and selectedFlourId values
                    const filteredReservations = response.data.reservations.filter(item => {
                        // Format the reservation date according to the same locale as the current date
                        const reservationDate = new Date(item.created_at).toLocaleDateString('en-GB');
                        return desiredFlourIds.includes(item.selectedFlourId) && reservationDate === currentDate;
                    });

                    // Sort the filtered reservations according to the desiredFlourIds order and then by Masa
                    filteredReservations.sort((a, b) => {
                        // Get the index of each item's selectedFlourId in the desiredFlourIds array
                        const indexA = desiredFlourIds.indexOf(a.selectedFlourId);
                        const indexB = desiredFlourIds.indexOf(b.selectedFlourId);

                        // Compare the indices first
                        if (indexA !== indexB) {
                            return indexA - indexB;
                        }

                        // If indices are equal, compare by Masa in ascending order
                        return parseInt(a.Masa, 10) - parseInt(b.Masa, 10);
                    });

                    // Set the filtered and sorted data as the state data
                    setData(filteredReservations);
                } else {
                    console.error("Invalid data type or structure:", response.data);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    };

    const isNotTodayOrAfterEndDay = (record) => {
        const today = new Date();
        const date = new Date(record.created_at);
        const endDay = record.endDay;

        return today.toDateString() !== date.toDateString() || (endDay && today > new Date(endDay));
    };

    useEffect(() => {
        const storedUserLevel = localStorage.getItem('s');
        if (storedUserLevel) {
            setUserLevel(storedUserLevel);
        }
    }, []);

    const rowClassName = (record) => {
        const isDisabled = isNotTodayOrAfterEndDay(record);
        return isDisabled ? "grayscale disabled" : "";
    };

    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('username') !== null;
        if (!isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    const isNotToday = (dateString) => {
        const today = new Date();
        const date = new Date(dateString);
        return today.toDateString() !== date.toDateString();
    };
    const selectedFlourIdMap = {
        E1L: "E1L",
        E1V: "E1V",
        E1R: "E1R",
        E2L: "E2L",
        E2R: "E2R",
        E3L: "E3L",
        E3M: "E3M",
        E3R: "E3R",
    };

    const columns = [
        {
            title: '#',
            dataIndex: '',
            key: 'frname',
        },
        {
            title: 'Ad',
            dataIndex: 'username',
            key: 'username',
            render: (text, record) => (
                <div>
                    <p>{text} {record.surename}</p>
                </div>
            ),
        },
        {
            title: 'Masa',
            dataIndex: 'Masa',
            key: 'Masa',
            width: 100,
            render: (text) => {
                const formattedText = text.replace(/^(10|20|30)/, '');
                return formattedText;
            },
        },
        {
            title: 'Not',
            dataIndex: 'note',
            key: 'note',
            width: 200,
        },
        {
            title: 'Mərtəbə Ərazi',
            dataIndex: 'selectedFlourId',
            key: 'selectedFlourId',
            sorter: (a, b) => {
                const orderA = selectedFlourIdMap[a.selectedFlourId] ? 1 : 0;
                const orderB = selectedFlourIdMap[b.selectedFlourId] ? 1 : 0;
                return orderA - orderB;
            },
            render: (text) => selectedFlourIdMap[text] || "Unknown",
            width: 100,
        },
        {
            title: 'R Tarix / Saat',
            dataIndex: 'selectedDay',
            key: 'selectedDay',
            render: (text, record) => {
                const date = new Date(text);
                return (
                    <div>
                        <p>{date.toLocaleDateString('en-GB')} {record.selectedTime}</p>
                    </div>
                );
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            width: 100,
            key: 'created_at',
            render: (text) => {
                // Convert `created_at` data from a string to a date object
                const date = new Date(text);
                return date.toLocaleString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "pending" ? "yellow" : text === "complete" ? "green" : "red"}>
                    {text}
                </Tag>
            ),
        },
    ];

    useEffect(() => {
        fetchData();
        // Trigger the print after 3 seconds
        const printTimeout = setTimeout(() => {
            handlePrint();
        }, 1000);

        return () => {
            // Clear the timeout on component unmount to avoid memory leaks
            clearTimeout(printTimeout);
        };
    }, []);

    const handlePrint = () => {
        // Check if invoice section exists before printing
        const invoiceSection = document.getElementById('invoice-section');
        if (invoiceSection) {
            const printDateDiv = document.createElement('div');
            printDateDiv.innerHTML = `<p style="text-align: center; bottom:10px;">Printed on: ${new Date().toLocaleString()} By Marketing | VERSİON: 1.0.1</p>`;
            invoiceSection.appendChild(printDateDiv);

            const originalContents = invoiceSection.innerHTML;

            // Replace 'printContents' with 'originalContents'
            document.body.innerHTML = originalContents;
            window.print();

            invoiceSection.removeChild(printDateDiv);
        } else {
            console.error("Invoice section not found in DOM.");
        }
    };

    return (
        <>
            <div className="dibhaa pritlistrezv" id="invoice-section">
                <div className='infosds' style={{ margin: "10px 25px" }}>
                    <div className='ckdj'>
                        <div className="redbox" />-
                        <p style={{ fontSize: '16px' }}>Rezerv edilib</p>
                    </div>
                    <div className='ckdj'>
                        <div className="greenbox" />-
                        <p style={{ fontSize: '16px' }}>Imtina edilib</p>
                    </div>
                    <div className='ckdj'>
                        <div className="greenboxBorder" />-
                        <p style={{ fontSize: '16px' }}>Boş Masalar</p>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                    pagination={false}
                    rowClassName={rowClassName}
                />
                <Space>
                    <MapM1ellips />
                    <MapM2ellips />
                    <MapM3ellips />
                </Space>
            </div>
        </>
    );
};

export default PrintpageEllips;
