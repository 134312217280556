import { DeleteOutlined, EditOutlined, FilePdfOutlined, IdcardOutlined, MoreOutlined, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, Image, Input, Menu, Modal, Skeleton, Space, Table, Tag } from 'antd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Link, useNavigate } from 'react-router-dom';
const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;
function AppGirls() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalopen, setIsModalopen] = useState(false);
  const [staffOutReason, setStaffOutReason] = useState('');
  const [staffOutDate, setStaffOutDate] = useState('');
  const searchInput = useRef(null);
  const staffOutReasonInputRef = useRef(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [visibleValues, setVisibleValues] = useState({});
  const [searchText, setSearchText] = useState("");
  const [open, setopen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [pin, setPin] = useState('');
  const pinInputRef = useRef();
  const [searchedColumn, setSearchedColumn] = useState("");
  const [permissions, setPermissions] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    console.log('Stored Permissions:', storedPermissions);

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }

    // Check if GuidesAzAdd permission is 0 and navigate if true
    if (storedPermissions && storedPermissions[0].Abone === 0) {
      navigate("/");
    }
  }, []);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const handleDelete = async () => {
    try {
      // Perform PIN validation here
      if (pin === '0001') {
        const response = await axios.delete(`${CONN_KEY_SENAT}AddGrils.php?userid=${itemToDelete.userid}`);
        if (response.status === 200 && response.data.code === 200) {
          // Handle success, e.g., update the table or show a success message
          Modal.error({
            title: 'Error',
            content: 'Yanlış PIN. Element silinməyib.',
          });
        } else {
          // Show an error message from the API response

          Modal.success({
            title: 'Success',
            content: 'Abunəçi uğurla silindi.',
          });
        }
      } else {
        // Incorrect PIN
        Modal.error({
          title: 'Error',
          content: 'Yanlış PIN. Element silinməyib.',
        });
      }
    } catch (error) {
      // Handle error, e.g., show a generic error message
      console.error(error);
      Modal.error({
        title: 'Error',
        content: 'Abunəçini silərkən xəta baş verdi.',
      });
    } finally {
      setopen(false);
      setItemToDelete(null);
      setPin(''); // Reset the PIN input
    }
  };
  const handleCancel = () => {
    setopen(false);
    setItemToDelete(null);
    setPin(''); // Reset the PIN input
  };
  const showDeleteConfirm = (record) => {
    setItemToDelete(record);
    setopen(true);
  };
  const handleBlock = (record) => {
    setSelectedRecord(record);
    setStaffOutReason('');
    setStaffOutDate('');
    setIsModalopen(true);
  };
  const handleUnBlock = (record) => {
    Modal.confirm({
      title: 'Diqqət',
      content: 'Bu Abonəcini Blokunu qaldırmaq istədiyinizə əminsiniz?',
      okText: 'Bloku qaldır',
      okType: 'danger',
      cancelText: 'Ləğv et',
      onOk: async () => {
        try {
          const formData = new FormData();
          formData.append('userid', record.userid);
          const response = await axios.post(`${CONN_KEY_SENAT}UnblockGirls.php`, formData);
          console.log(response.data); // You can log the response data to see the server's response
          // Handle success, e.g., update the table or show a success message
          // Update the table or perform any necessary actions
        } catch (error) {
          // Handle error, e.g., show an error message
          console.error(error);
        }
      },
    });
  };
  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  const fetchData = () => {
    axios.get(CONN_KEY_SENAT + 'AddGrils.php')
      .then(response => {
        const updatedData = response.data.map(item => ({ ...item, key: item.id }));
        setData(updatedData);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const toggleVisibility = (key) => {
    const password = prompt('Enter password to toggle visibility:');
    // Check if password is correct (for demonstration, use 'password' as the correct password)
    if (password === ' ') {
      setVisibleValues((prevValues) => ({
        ...prevValues,
        [key]: !prevValues[key],
      }));
    } else {
      alert('Incorrect password. Visibility toggle failed.');
    }
  };

  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }
  }, []);

  const canSeeSectionsAbone = permissions.Abone === 1;
  const canSeeSectionsAboneEdit = permissions.AboneEdit === 1;
  const canSeeSectionsAboneDelete = permissions.AboneDelete === 1;
  const canSeeSectionsAboneBlock = permissions.AboneBlock === 1;
  const canSeeSectionsAboneUnblock = permissions.AboneUnblock === 1;

  const renderActions = (text, record) => {
    const menu = (
      <Menu>
        {canSeeSectionsAboneDelete && (
          <Menu.Item key="delete" onClick={() => showDeleteConfirm(record)}>
            <DeleteOutlined /> Delete
          </Menu.Item>
        )}
        {canSeeSectionsAboneEdit && (
          <Menu.Item key="edit">
            <Link to={`/Update-Üzv/${record.userid}`}>
              <EditOutlined /> Edit
            </Link>
          </Menu.Item>
        )}
        {canSeeSectionsAboneBlock && (
          <Menu.Item key="block" onClick={() => handleBlock(record)}>
            <StopOutlined /> Block
          </Menu.Item>
        )}
        {canSeeSectionsAboneUnblock && (
          <Menu.Item key="unblock" onClick={() => handleUnBlock(record)}>
            <StopOutlined /> Unblock
          </Menu.Item>
        )}
      </Menu>
    );
    return (
      <Space>
        <Dropdown overlay={menu} placement="bottomRight">
          <Button> <MoreOutlined /></Button>
        </Dropdown>
      </Space>
    );
  };
  const columns = [
    {
      title: "3x4",
      dataIndex: "filePP",
      key: "1",
      width: 80,
      render: (imagePath, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record.Status && (
            <div className="blockicondfaa dark:bg-slate-800">
              <StopOutlined
                style={{ color: "red", fontSize: 24, position: "absolute", zIndex: '11111' }}
              />
            </div>
          )}
          <Image
            className={`imgpp ${record.Status ? "grayscale" : ""}`}
            src={`${CONN_KEY_SENAT}3x4Girl/${imagePath}`}
            alt="filePP"
            style={{ zIndex: "30" }}
          />
        </div>
      ),
    },
    {
      title: 'V/N',
      dataIndex: 'userid',
      key: 'userid',
      width: 90,
      sorter: {
        compare: (a, b) => {
          const useridA = a.userid != null ? a.userid.toString() : ""; // Handle null or undefined values and convert to string
          const useridB = b.userid != null ? b.userid.toString() : ""; // Handle null or undefined values and convert to string

          // Convert to numbers if possible, fallback to strings otherwise
          const numA = parseFloat(useridA);
          const numB = parseFloat(useridB);

          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB; // Numeric comparison
          }

          return useridA.localeCompare(useridB); // String comparison as fallback
        },
        multiple: 2,
      },
      render: (value, record) => (
        <h3 className='bold'>{value}</h3>
      ),
      ...getColumnSearchProps('userid'),
    },
    {
      title: 'İstifadeci adı',
      dataIndex: 'username',
      width: 100,
      key: 'username',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Soy ad',
      dataIndex: 'surename',
      width: 100,
      key: 'surename',
      ...getColumnSearchProps('surename'),
    },
    {
      title: 'Ata adı',
      dataIndex: 'dadname',
      width: 100,
      key: 'dadname',
      ...getColumnSearchProps('dadname'),
    },
    {
      title: 'Telefon',
      dataIndex: 'Phone',
      width: 100,
      key: 'Phone',
      ...getColumnSearchProps('Phone'),

    },
    {
      title: 'Fin',
      dataIndex: 'Fin',
      width: 50,
      key: 'Fin',
      ...getColumnSearchProps('Fin'),
    },
    {
      title: 'Doğum Günü',
      dataIndex: 'datebhrd',  // This should match the key used in the sorter function
      width: 100,
      key: 'datebhrd',
      sorter: (a, b) => {
        const dateA = new Date(a.datebhrd.split('.').reverse().join('-') || "1970-01-01"); // Convert to proper date format
        const dateB = new Date(b.datebhrd.split('.').reverse().join('-') || "1970-01-01"); // Convert to proper date format
        return dateA - dateB;
      },
      onFilter: (value, record) => {
        return record.datebhrd.endsWith(value);
      },
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      width: 70,
      sorter: (a, b) => {
        const StatusA = a.Status || ""; // Handle null values by providing an empty string
        const StatusB = b.Status || ""; // Handle null values by providing an empty string
        return StatusA.localeCompare(StatusB);
      },
      render: (text) => <Tag color={text ? 'red' : 'green'}>{text ? 'Block' : 'Yaşıl'}</Tag>,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'BlockSeb',
    //   key: 'BlockSeb',
    //   width: 80,
    //   render: (BlockSeb, record) => {
    //     const visible = visibleValues[record.key];
    //     const blurredBlockSeb = BlockSeb ? BlockSeb.replace(/.(?=.{0})/g, '*') : null; // Blur all characters except the last four
    //     const displayedBlockSeb = visible ? BlockSeb : blurredBlockSeb;
    //     return (
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         <span>{displayedBlockSeb ? displayedBlockSeb : <Badge status="processing" text="Aktiv" />}</span>
    //         <Button
    //           type="link"
    //           onClick={() => toggleVisibility(record.key)}
    //         >
    //           {visible ? 'Hide' : 'Show'}
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
    {
      title: 'Action',
      dataIndex: 'userid',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <Space>
          <Link to={`/CartUzv/${record.userid}`} target='_blank'>
            <Button className='ColorMain' icon={<IdcardOutlined />}></Button>
          </Link>
          <Link to={`/PDFUzv/${record.userid}`} target='_blank'>
            <Button className='ColorMain' icon={<FilePdfOutlined />}></Button>
          </Link>
        </Space>
      ),
    },
    {
      title: 'More',
      dataIndex: 'userid',
      key: 'more',
      fixed: 'right',
      render: renderActions,
      width: 80,
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    // handle table change event and update the state if necessary
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination,
    }));
  };
  const handleModalSubmit = async () => {
    try {
      const response = await axios.post(`${CONN_KEY_SENAT}BlockSebGirls.php`, {
        userid: selectedRecord.userid,
        BlockSeb: staffOutReason,
        BlockSebdate: staffOutDate,
      });
      console.log(response.data);
      setIsModalopen(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleModalCancel = () => {
    setIsModalopen(false);
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : data.length > 0 ? (
        <>
          <Table
            columns={columns}
            sticky
            dataSource={data}
            loading={isLoading}
            onChange={handleTableChange}
            scroll={{ y: 550, x: 'max-content' }}
            pagination={{
              ...tableParams.pagination,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200', '500', '800'],
            }}
            summary={() => (
              <Table.Summary fixed='top'>
              </Table.Summary>
            )}
            locale={{
              emptyText: (
                <Empty
                  description="No Data"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ marginTop: 50 }}
                />
              ),
            }}
            rowClassName={(record) => (record.Status ? 'disabled' : '')}
          />
          <Modal
            title="Block"
            open={isModalopen}
            onOk={handleModalSubmit}
            onCancel={handleModalCancel}
            destroyOnClose
          >
            <Space>
              <Input
                ref={staffOutReasonInputRef}
                placeholder="Reason"
                name='BlockSeb'
                value={staffOutReason}
                onChange={(e) => setStaffOutReason(e.target.value)}
              />
              <Input
                placeholder="Date"
                name='BlockSebdate'
                value={staffOutDate}
                onChange={(e) => setStaffOutDate(e.target.value)}
              />
            </Space>
          </Modal>
          <Modal
            title="Confirm Deletion"
            open={open}
            onOk={handleDelete}
            onCancel={handleCancel}
          >
            <div>
              <p> Bu Bələdçini silmək istədiyinizə əminsiniz? Bu əməliyyat geri qaytarıla bilməz. Elementin silinməsini təsdiqləmək üçün PİN kodunuzu daxil edin:</p>
              <Input
                ref={pinInputRef}
                type="password"
                className="dark:bg-slate-800"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </div>
          </Modal>
        </>
      ) : (
        <div>
          <p>No data found.</p>
          <Empty />
          <Button type="primary" className='ColorMain'>
            <Link to="/add">Add</Link>
          </Button>
        </div>
      )}
    </>
  );
}

export default AppGirls;