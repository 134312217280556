import React from 'react';
import FaceRecognition from '../components/Facetracker';

const Face = () => {
    return (
        <div className="App">
            <FaceRecognition />
        </div>
    );
};

export default Face;
