import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { message, Form, Input, Button, Card, Row, Col, Select, Space } from 'antd';

const { Option } = Select;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const UpdateMCGeu = () => {
    const [data, setData] = useState({});
    const [brands, setBrands] = useState([]);
    const { menu_Id } = useParams();

    useEffect(() => {
        // Fetch brand data from the server
        const fetchBrands = async () => {
            try {
                const response = await axios.get(CONN_KEY + 'menuGeudata.php');
                setBrands(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchBrands();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}menuGeudata.php?menu_id=${menu_Id}`);
                setData(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [menu_Id]);

    const onFinish = async (values) => {
        try {
            const payload = {
                ...values,
                menu_id: menu_Id, // Include the menu_id in the payload
            };

            const response = await axios.put(`${CONN_KEY}updatemenuGeudata.php`, payload);

            if (response.data.success) {
                message.success(response.data.message);
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const [form] = Form.useForm();

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            form.setFieldsValue(data);
        }
    }, [form, data]);

    const options = [
        {
            value: 'WHISKEY',
        },
        {
            value: 'VODKA',
        },
        {
            value: 'TEQUILA',
        },
        {
            value: 'GIN',
        },
        {
            value: 'COGNAC',
        },
        {
            value: 'CHAMPAGNE',
        },
        {
            value: 'WİNE',
        },
        {
            value: 'VERMUT',
        },
        {
            value: 'APERETIV',
        },
        {
            value: 'LIQUOR',
        },
        {
            value: 'ROM',
        },
        {
            value: 'BEER',
        },
        {
            value: 'SNACKS',
        },
        {
            value: 'TOBACCO',
        },
        {
            value: 'COCKTAILS',
        },
        {
            value: 'SOFT DRINKS',
        },
        {
            value: 'FIREWORKS',
        },
        {
            value: 'Hookah',
        },
    ];

    return (
        <div className="container">
            <h1>ID:{menu_Id}</h1>
            <Form
                form={form}
                onFinish={onFinish}
                encType="multipart/form-data"
                className="form-Add container"
                layout="vertical"
            >
                <Card>
                    <div style={{ gap: '10px' }}>
                        <Row gutter={[8, 8]}>
                            <Col span={6}>
                                <Form.Item
                                    className='ant-form-item' label="Catagory" name="Catagory" >
                                    <Select style={{ width: '100%' }} size="large" options={options} showArrow />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    className='ant-form-item' label="Product" name="product">
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.product}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    className='ant-form-item' label="Price" name="price">
                                    <Input style={{ width: '100%' }} size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Space>
                            <Button htmlType="submit" type="primary" className="ColorMain">
                                Edit
                            </Button>
                        </Space>
                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default UpdateMCGeu;
