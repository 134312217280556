import { FilePdfOutlined } from "@ant-design/icons";
import { Button, Skeleton, Slider, Space, message } from "antd"; // Import Slider from Ant Design
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../App.css";

const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;

function FakturaCUZV() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [fontSize, setFontSize] = useState(26); // Default font size
  const { UserId } = useParams();
  const invoiceSectionRef = useRef(null);
  const [permissions, setPermissions] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    console.log('Stored Permissions:', storedPermissions);

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }

    // Check if GuidesAzAdd permission is 0 and navigate if true
    if (storedPermissions && storedPermissions[0].Abone === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fakturaUrl = `${CONN_KEY_SENAT}AddGrils.php?userid=${UserId}`;
        const fakturaResponse = await axios.get(fakturaUrl);
        setData(fakturaResponse.data);

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [UserId]);

  const handlePrint = () => {
    if (invoiceSectionRef.current) {
      const printContents = invoiceSectionRef.current.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();

      window.onafterprint = () => {
        // After printing is done, display a message
        message.info("Printing is complete. Refreshing the page...");

        // Wait for a short delay (adjust the delay as needed)
        setTimeout(() => {
          // Restore the original content
          document.body.innerHTML = originalContents;

          // Refresh the page automatically
          window.location.reload();
        }, 1000); // Refresh after 1 seconds (1000 milliseconds)
      };
    }
  };

  const onChange = (value) => {
    // Set the font size based on the Slider value
    setFontSize(value);
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Space className="user-ids">
            <Button className="ColorMain" onClick={handlePrint}>
              Print
            </Button>
            <Link to={`/PDFUzv/${data.userid}`} target="_blank">
              <Button icon={<FilePdfOutlined />}>PDF</Button>
            </Link>
            <Slider
              className="adplgfsd"
              style={{ width: 350 }}
              min={10}
              max={40} // Adjust the max value as needed
              onChange={onChange}
              value={fontSize}
            />
          </Space>
          <div id="invoice-section" ref={invoiceSectionRef}>
            <div className="pages" style={{ display: 'flex', flexDirection: "column" }}>
              <div className="CarVisitGRL" >
                <div className="UZVihd">
                  <div className="useridid">
                    <h1 className="isduzv" style={{ color: "#fff", fontWeight: 'bold', top: '254px', left: '-33px', position: 'relative' }}>
                      V№{data.userid}
                    </h1>
                  </div>
                  {/* <div className="hsaddfaUZV" style={{ color: "#D9B79A", transform: 'rotate(90deg)', display: 'flex', flexDirection: "column" }}>
                    <div className="fontsadUZV">
                      {data.username}
                    </div>
                    <div className="fontsadUZV">
                      {data.surename}
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="CarVisitGRL" style={{ marginTop: '-105px' }}>
                <div className="UZVihdBCK">
                  <div className="useridid">
                    <h1 className="isduzv" style={{ color: "#000", fontWeight: 'bold', top: '235px', left: '-19px', position: 'relative' }}>
                      V№{data.userid}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
      }
    </>
  );
}

export default FakturaCUZV;
