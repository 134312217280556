import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Storyadd from "./AddStory";
import NewUsers from "./NewUserAdd";
import Passchange from "./PassChangeComp";
import Settingsapp from "./SettingsApp";

const Settings = () => {
  const [permissions, setPermissions] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
      // Redirect if no GuidesAzAdd permission
      if (storedPermissions[0].Admin === 0) {
        navigate("/");
      }
    }
  }, [navigate]);

  const canSeeSectionsAdmin = permissions.Admin === 1;

  const renderAdminSection = (Component) => (
    canSeeSectionsAdmin ? (
      <Card style={{ width: "100%" }}>
        <Component />
      </Card>
    ) : null
  );

  return (
    <div className="dfksmcj grid grid-flow-col auto-cols-max md:auto-cols-min">
      <div style={{ width: "100%", gap: 16 }}>
        <div className="md:grid grid-flow-col w-full auto-cols-max md:auto-cols-min" style={{ gap: 16 }}>
          <Card style={{ width: "100%" }}>
            <Passchange />
          </Card>
          {renderAdminSection(NewUsers)}
          {renderAdminSection(Settingsapp)}
        </div>
      </div>
      <div style={{ width: "100%", gap: 16 }}>
        <Card style={{ width: "100%" }}>
          <Storyadd />
        </Card>
      </div>
    </div>
  );
};

export default Settings;
