import { Layout, theme } from 'antd';
import Tables from '../../components/Girls/Tablegirls';
import Footers from '../../components/Footer';
import Headers from '../../components/Header';
import Siders from '../../components/Sider';
const { Content } = Layout;

const TableGirls = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 10px',
                        // height: '80vh'
                    }}
                >
                    <div
                        style={{
                            // padding: 24,
                            minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <h1>Üzvlər</h1>
                        <br /><br />
                        <Tables />
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};

export default TableGirls;
