import { Button, Space, Empty, Skeleton, Table, Image, Input, Modal, Tag, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import { IdcardOutlined, SearchOutlined, FilePdfOutlined, EditOutlined, StopOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

function TableGeo() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isModalopen, setIsModalopen] = useState(false);
  const [staffOutReason, setStaffOutReason] = useState('');
  const [staffOutDate, setStaffOutDate] = useState('');
  const staffOutReasonInputRef = useRef(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const navigate = useNavigate();

  const [pin, setPin] = useState('');
  const [open, setopen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const pinInputRef = useRef();

  const showDeleteConfirm = (record) => {
    setItemToDelete(record);
    setopen(true);
  };
  const handleCancel = () => {
    setopen(false);
    setItemToDelete(null);
    setPin(''); // Reset the PIN input
  };
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('username') !== null ;
    if (!isLoggedIn) {
      navigate('/');
    } else {
      console.log('User is logged in.');
      // console.log('Username:', localStorage.getItem('username'));
      // console.log('Level:', localStorage.getItem('s'));
    }
  }, [navigate]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 15,
    },
  });
  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  const fetchData = () => {
    axios.get(CONN_KEY + 'AddGE.php')
      .then(response => {
        const updatedData = response.data.map(item => ({ ...item, key: item.id }));
        setData(updatedData);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            surename="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            surename="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            surename="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            surename="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const columnValue = record[dataIndex];
      if (typeof columnValue === 'undefined') {
        return false; // Return false or a default value if the column value is undefined
      }
      return columnValue.toString().toLowerCase().includes(value.toLowerCase());
    },

    onFilterDropdownOpenChange: (open) => {
      if (open) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      if (typeof text === 'undefined') {
        return null; // Return null or a default value if the text is undefined
      }

      const highlightedText = searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      );

      return <span>{highlightedText}</span>;
    },

  });
  const renderActions = (text, record) => {
    const userLevel = localStorage.getItem('s');

    if (
      record &&
      record.key &&
      (userLevel === '$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy' ||
        userLevel === 'e8cf599ed55d6997e43e63998ec4020ca2cb3fbd')
    ) {
      const menu = (
        <Menu>
          <Menu.Item key="delete" onClick={() => showDeleteConfirm(record)}>
            <DeleteOutlined /> Delete
          </Menu.Item>
          <Menu.Item key="edit">
            <Link to={`/UpdateGE/${record.userid}`}>
              <EditOutlined /> Edit
            </Link>
          </Menu.Item>
          <Menu.Item key="block" onClick={() => handleBlock(record)}>
            <StopOutlined /> Block
          </Menu.Item>
          <Menu.Item key="unblock" onClick={() => handleUnBlock(record)}>
            <StopOutlined /> Unblock
          </Menu.Item>
        </Menu>
      );

      return (
        <Space>
          <Dropdown overlay={menu} placement="bottomRight">
            <Button> <MoreOutlined /></Button>
          </Dropdown>
        </Space>
      );
    } else {
      return null;
    }
  };
  const columns = [
    {
      title: "3x4",
      dataIndex: "filePP",
      key: "1",
      width: 80,
      render: (imagePath, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record.Status && (
            <div className="blockicondfaa">
              <StopOutlined
                style={{ color: "red", fontSize: 24, position: "absolute" }}
              />
            </div>
          )}
          <Image
            className={`imgpp ${record.Status ? "grayscale" : ""}`}
            src={`${CONN_KEY}3x4/${imagePath}`}
            alt="filePP"
            style={{ zIndex: "30" }}
          />
        </div>
      ),
    },
    {
      title: 'Voucher number',
      dataIndex: 'userid',
      key: '1',
      width: 150,
      sorter: {
        compare: (a, b) => b.userid.localeCompare(a.userid),
        multiple: 1,
      },
      render: (value, record) => {
        return (
          <>
            <h3 className='bold'>
              {value}{' '}
            </h3>
          </>
        );
      },
      ...getColumnSearchProps('userid'),
    },
    {
      title: 'Username',
      dataIndex: 'username', width: 150,
      key: '2',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Surname', width: 150,
      dataIndex: 'surename',
      key: '2',
      ...getColumnSearchProps('surename'),
    },
    {
      title: 'father name',
      dataIndex: 'dadname', width: 150,
      key: '2',
      ...getColumnSearchProps('dadname'),
    },
    {
      title: 'Phone number',
      dataIndex: 'Phone',
      width: 150,
      key: '265',
      ...getColumnSearchProps("Phone"),
    },
    {
      title: 'Personal NO',
      dataIndex: 'Fin', width: 150,
      key: '756',
      ...getColumnSearchProps('Fin'),

    },
    {
      title: 'Position',
      dataIndex: 'vezife', width: 150,
      key: '3',
      sorter: {
        compare: (a, b) => {
          if (a.vezife < b.vezife) {
            return -1;
          }
          if (a.vezife > b.vezife) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: 'Date',
      dataIndex: 'date_post', width: 150,
      key: '75654',
      sorter: {
        compare: (a, b) => {
          const dateA = new Date(a.date_post);
          const dateB = new Date(b.date_post);
          return dateA - dateB;
        },
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "9",
      width: 95,
      sorter: (a, b) => {
        const statusA = a.Status || ""; // Handle null values by providing an empty string
        const statusB = b.Status || ""; // Handle null values by providing an empty string
        return statusA.localeCompare(statusB);
      },
      onFilter: (value, record) => {
        if (value === "red") return record.Status;
        if (value === "green") return !record.Status;
        return true;
      },
      render: (text) => (
        <Tag color={text ? "red" : "green"}>{text ? "Bloked" : "Free"}</Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'userid',
      key: 'x',
      width: 150,
      render: (text, record) => (
        <Space>
          <Link to={`/CartGE/${record.userid}`} target='_blank'>
            <Button className={`ColorMain ${record.Status ? "ccolorblock" : ""}`} icon={<IdcardOutlined />}>
              Cart
            </Button>
          </Link>
          <Link to={`/PDFGE/${record.userid}`} target='_blank'>
            <Button className={`ColorMain ${record.Status ? "ccolorblock" : ""}`} icon={<FilePdfOutlined />}>
              PDF
            </Button>
          </Link>
        </Space>
      ),
    },
    {
      title: 'More',
      dataIndex: 'userid',
      key: '256321',
      render: renderActions,
      width: 80, // Set minimum width to 100 pixels
    },
  ];
  const handleDelete = async () => {
    try {
      // Perform PIN validation here
      if (pin === '0102') {
        const response = await axios.delete(`${CONN_KEY}AddGE.php?userid=${itemToDelete.userid}`);
        if (response.status === 200 && response.data.code === 200) {
          // Handle success, e.g., update the table or show a success message
          Modal.error({
            title: 'Error',
            content: 'Incorrect PIN. Guide not deleted.',
          });
        } else {
          // Show an error message from the API response

          Modal.success({
            title: 'Success',
            content: 'Product deleted successfully.',
          });
        }
      } else {
        // Incorrect PIN
        Modal.error({
          title: 'Error',
          content: 'Incorrect PIN. Guide not deleted.',
        });
      }
    } catch (error) {
      // Handle error, e.g., show a generic error message
      console.error(error);
      Modal.error({
        title: 'Error',
        content: 'An error occurred while deleting the Guide.',
      });
    } finally {
      setopen(false);
      setItemToDelete(null);
      setPin(''); // Reset the PIN input
    }
  };
  const handleUnBlock = (record) => {
    Modal.confirm({
      title: 'Attention',
      content: 'Are you sure you want to remove this Guide Block?',
      okText: 'Unblock',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          const formData = new FormData();
          formData.append('userid', record.userid);
          const response = await axios.post(`${CONN_KEY}UnblockGE.php`, formData);
          console.log(response.data); // You can log the response data to see the server's response
          // Handle success, e.g., update the table or show a success message
          // Update the table or perform any necessary actions
        } catch (error) {
          // Handle error, e.g., show an error message
          console.error(error);
        }
      },
    });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // handle table change event and update the state if necessary
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination,
    }));
  };
  const handleBlock = (record) => {
    setSelectedRecord(record);
    setStaffOutReason("");
    setStaffOutDate("");
    setIsModalopen(true);
  };
  const handleModalSubmit = async () => {
    try {
      const response = await axios.post(`${CONN_KEY}BlockSebGE.php`, {
        userid: selectedRecord.userid,
        BlockSeb: staffOutReason,
        BlockSebdate: staffOutDate,
      });
      console.log(response.data);
      setIsModalopen(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleModalCancel = () => {
    setIsModalopen(false);
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : data.length > 0 ? (
        <>
          <Modal
            title="Confirm Deletion"
            open={open}
            onOk={handleDelete}
            onCancel={handleCancel}
          >
            <div>
              <p> Are you sure you want to delete this Guide? This transaction is non-refundable. Enter your PIN to confirm item removal:</p>
              <Input
                ref={pinInputRef}
                type="password"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </div>
          </Modal>
          <Table
            columns={columns}
            sticky
            dataSource={data}
            loading={isLoading}
            onChange={handleTableChange}
            scroll={{ y: 550, x: 'max-content' }}
            pagination={{
              ...tableParams.pagination,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200', '500', '800'],
            }}
            summary={() => (
              <Table.Summary fixed='top'>
              </Table.Summary>
            )}
            locale={{
              emptyText: (
                <Empty
                  description="No Data"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ marginTop: 50 }}
                />
              ),
            }}
            rowClassName={(record) => (record.Status ? "disabled" : "")}
          />
          <Modal
            title="Block"
            open={isModalopen}
            onOk={handleModalSubmit}
            onCancel={handleModalCancel}
            destroyOnClose
          >
            <Space>
              <Input
                ref={staffOutReasonInputRef}
                placeholder="Reason"
                name='BlockSeb'
                value={staffOutReason}
                onChange={(e) => setStaffOutReason(e.target.value)}
              />
              <Input
                placeholder="Date"
                name='BlockSebdate'
                value={staffOutDate}
                onChange={(e) => setStaffOutDate(e.target.value)}
              />
            </Space>
          </Modal>
        </>
      ) : (
        <div>
          {/* <p>No data found.</p> */}
          <Empty />
          {/* <Button type="primary" className='ColorMain'>
            <Link to="/add">Add</Link>
          </Button> */}
        </div>
      )}
    </>
  );
}

export default TableGeo;