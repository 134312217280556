import { Layout, theme } from 'antd';
import Footers from '../../components/Footer';
import Headers from '../../components/Header';
import Siders from '../../components/Sider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UpdateMCGeu from '../../components/MenuGeo/UpdatemenuGeu';
const { Content } = Layout;

const UpdateMGeu = () => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 10px',
                    }}
                >
                    <div
                        style={{
                            padding: 4,
                            minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <UpdateMCGeu/>
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};

export default UpdateMGeu;
