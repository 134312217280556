import { FieldNumberOutlined, IdcardOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Drawer, Form, Input, InputNumber, message, Modal, Row, Select, Space, Upload } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { Link, useNavigate } from "react-router-dom";
import img3 from "../../images/3month.png";
import img6 from "../../images/6manth.png";
import Webcamcomp from "../CameraComp";
import UserIds from "../NullIDS";
import Uplodedimages from "../uploadedimages";
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const { Option } = Select;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const SellingItem = () => {
  const [inputs, setInputs] = useState({});
  const [brands, setBrands] = useState([]);
  const [maxUserId, setMaxUserId] = useState(0);
  const [open, setOpen] = useState(false);
  const [openCam, setOpenCam] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const navigate = useNavigate();
  const [oneuserid, setOneUserId] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalopenTime, setIsModalopenTime] = useState(false);
  const [permissions, setPermissions] = useState({});
  const showDrawer = () => {
    setOpen(true);
  };
  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
    console.log('Stored Permissions:', storedPermissions);

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }

    // Check if GuidesAzAdd permission is 0 and navigate if true
    if (storedPermissions && storedPermissions[0].GuidesAzAdd === 0) {
      navigate("/");
    }
  }, []);


  const showDrawercam = () => {
    setOpenCam(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onCloseCam = () => {
    setOpenCam(false);
  };
  useEffect(() => {
    axios
      .get(CONN_KEY + "Add.php")
      .then((response) => {
        setBrands(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    try {
      console.log("Received values of form: ", values);

      const formData = new FormData();
      formData.append("userid", values.userid);
      formData.append("username", values.username);
      formData.append("surename", values.surename);
      formData.append("dadname", values.dadname);
      formData.append("Beletcinum", values.Beletcinum);
      formData.append("email", values.email);
      formData.append("Fin", values.Fin);
      formData.append("datebhrd", values.datebhrd);
      formData.append("Country", values.Country);
      formData.append("Phone", values.Phone);
      formData.append("vezife", values.vezife);
      formData.append("filePP", values.filePP?.[0]?.originFileObj);
      formData.append("fileSH", values.fileSH?.[0]?.originFileObj);

      console.log("formData:", formData);

      const barcodeResponse = await axios.get(
        `${CONN_KEY}Add.php?userid=${values.userid}`
      );
      if (barcodeResponse.data.status === 1) {
        message.error("Bu istifadəçi artıq yükləmisiniz.");
        return;
      }

      console.log("Sending formData to first endpoint...");
      const response1 = await axios.post(`${CONN_KEY}Add.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response1:", response1);

      console.log("Sending formData to second endpoint...");
      const response2 = await axios.post(
        "https://senategroup.az/Data/api/Add.php",
        formData, // Assuming you want to send the same formData
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Response2:", response2);

      if (response1.data.status === 1 || response2.data.status === 1) {
        message.error("Bu istifadəçi artıq yükləmisiniz.");
      } else {
        message.success("İstifadəçi uğurla əlavə edildi");
        form.resetFields();
        // window.location.href = `/Cart/${values.userid}`;
      }
    } catch (error) {
      console.log(error);
      message.error("An error occurred. Please try again later.");
    }
  };
  const onFinishFailed = () => {
    message.error("Müvafiq sahələr boş qala bilməz!");
  };
  const handleChange = (name, value) => {
    if (name === 'Phone') {
      const formattedPhone = value.replace(/\D/g, ''); // Remove non-numeric characters
      let formattedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (formattedValue.length > 0) {
        formattedValue = '(' + formattedValue.substring(0, 3);
        if (formattedValue.length > 3) {
          formattedValue += ') ' + formattedValue.substring(3, 6);
        }
        if (formattedValue.length > 7) {
          formattedValue += ' ' + formattedValue.substring(7, 9);
        }
        if (formattedValue.length > 9) {
          formattedValue += ' ' + formattedValue.substring(9, 11);
        }
      }
      if (formattedPhone.length <= 10) {
        // Format phone number: (00) ___ __ __
        const formattedValue = formattedPhone.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '($1) $2 $3 $4');
        setInputs((values) => ({ ...values, [name]: formattedValue }));
      } else {
        // Handle cases where the user deletes characters and ensure the formatting is updated accordingly
        setInputs((values) => ({ ...values, [name]: formattedPhone.substring(0, 10) }));
      }
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };
  const onGenderChange = (value) => {
    // Handle the gender change event here
    // console.log("Seçilmiş Vəzifə:", value);
  };
  useEffect(() => {
    axios
      .get(CONN_KEY + "Add.php")
      .then((response) => {
        const data = response.data;
        const maxId = Math.max(...data.map((item) => item.userid));
        const useridoneValue = maxId + 1;
        setMaxUserId(maxId);
        setBrands(data);
        setOneUserId(useridoneValue); // Update oneuserid state here
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const fileList = [];
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleCartButtonClick = (record) => {
    setSelectedRecord(record);
    setIsModalopenTime(true);
  };
  const handleCardClick = async (cardId) => {
    try {
      if (!selectedRecord) {
        console.error("No selected record to associate with the card.");
        return;
      }

      const response = await axios.post(
        `${CONN_KEY}timerbuy.php?userid=${selectedRecord?.userid}&cardId=${cardId}`
      );

      // Handle the API response as needed
      console.log("API Response:", response.data);

      // Close the modal if needed
      setIsModalopenTime(false);
    } catch (error) {
      console.error("Error in handleCardClick:", error);
      // Handle error, show an error message, etc.
    }
  };
  const handleCancel = () => setPreviewOpen(false);
  return (
    <div className="container">
      {contextHolder}
      <Modal
        title="Kartın müddətini seçin"
        open={isModalopenTime}
        onCancel={() => setIsModalopenTime(false)}
        destroyOnClose
        footer={null}
      >
        <div className="flex gap-2">
          <Link to={`/Cart/${selectedRecord?.userid}`}>
            <Card
              className="flex hover:bg-blue-200"
              hoverable
              onClick={() => handleCardClick(3)}
            >
              <img src={img3} alt="Card 3" />
            </Card>
          </Link>
          <Link to={`/Cart/${selectedRecord?.userid}`}>
            <Card
              hoverable
              className="flex hover:bg-blue-200"
              onClick={() => handleCardClick(6)}
            >
              <img src={img6} alt="Card 6" />
            </Card>
          </Link>
        </div>
        <br />
        <Link to={`/Cart/${selectedRecord?.userid}`}>
          <Button
            // className={`ColorMain`}
            icon={<IdcardOutlined />}
          >
            Cart-{selectedRecord?.userid}
          </Button>
        </Link>
      </Modal>
      <Form
        onFinish={onFinish}
        encType="multipart/form-data"
        className="form-Add container"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinishFailed={onFinishFailed}
      >
        <Card
          title="Yeni Bələdçi"
          extra={
            <Space>
              <Button type="default" onClick={showDrawer}>
                Böş V/N
              </Button>
              <Button
                type="default"
                className="camera-drawer"
                onClick={showDrawercam}
              >
                Camera
              </Button>
            </Space>
          }
        >
          <div style={{ gap: "5" }}>
            <Row gutter={[16, 16]}>
              <Form.Item
                className="ant-form-item"
                label="Vn №"
                name="userid"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa vəsiqə nömrəsi seçin!",
                  },
                ]}
                extra={`Qeyd olunacaq V/N ${oneuserid.toString()}`}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  name="userid"
                  initialValues={oneuserid}
                  onChange={(value) => handleChange("userid", value)}
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                  }}
                  placeholder={`Qeyd olunacaq ${oneuserid.toString()}`}
                  min={1}
                  formatter={(value) => `${value}`}
                  parser={(value) => value.replace(/\D/g, "")}
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Soy ad"
                name="surename"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa Soy ad daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="surename"
                  size="large"
                  onChange={handleChange}
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                  }}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İstifadəçi adı"
                name="username"
                onKeyPress={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault(); // Prevent typing space character
                  }
                }}
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa ad daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="username"
                  size="large"
                  onChange={handleChange}
                  placeholder="Ad"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Ata adı"
                name="dadname"
                onKeyPress={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault(); // Prevent typing space character
                  }
                }}
              >
                <Input
                  style={{ width: "100%" }}
                  name="dadname"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="İstifadəçi Fin"
                name="Fin"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa Fin daxil edin",
                  },
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  name="Fin"
                  size="large"
                  value={inputs.Fin}
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                    e.target.value = e.target.value.toUpperCase();
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value.toUpperCase().replace(/\s/g, ''); // Convert to uppercase and remove spaces
                    setInputs((prevInputs) => ({
                      ...prevInputs,
                      Fin: newValue.replace(/[^A-Z0-9]/g, ''), // Replace non-alphanumeric characters
                    }));
                  }}
                  placeholder="00AABBC"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Doğum günü"
                name="datebhrd"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa doğum gününü daxil edin",
                  },
                ]}
              >
                <InputMask
                  mask="99.99.9999" // Define the input mask pattern
                  maskChar="0" // Use '0' as the mask character
                  value={inputs.datebhrd} // Provide the input value (optional)
                  onChange={(e) => handleChange("datebhrd", e.target.value)} // Handle change
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      style={{ width: "100%" }}
                      name="datebhrd"
                      size="large"
                      placeholder="DD.MM.YYYY"
                    />
                  )}
                </InputMask>
              </Form.Item>
              <Form.Item
                className="ant-form-item" label="Ölkə" name="Country">
                <Input
                  style={{ width: "100%" }}
                  onKeyPress={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent typing space character
                    }
                  }}
                  name="Country"
                  size="large"
                  onChange={handleChange}
                  placeholder="Azəraycan"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Telefon nömrəsi"
                name="Phone"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa Telefon nömrəsini daxil edin",
                  },
                ]}
              >
                <InputMask
                  mask="999 999 99 99" // Define the input mask
                  maskChar=" " // Use space as the mask character
                  value={""} // Set an empty string or the phone number value
                  onChange={(e) => handleChange("Phone", e.target.value)}
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      style={{ width: "100%" }}
                      name="Phone"
                      size="large"
                      placeholder="(000) 000 00 00"
                    />
                  )}
                </InputMask>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Vəzifə"
                name="vezife"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa vəzifə secin",
                  },
                ]}
              >
                <Select
                  placeholder="Vəzifə Secin"
                  onChange={onGenderChange}
                  allowClear
                  size="large"
                  name="vezife"
                >
                  <Option value="Tour Guide">Bədətçi</Option>
                  <Option value="Driver">Sürücü</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa bələdçi secin daxil edin",
                  },
                ]}
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.vezife !== currentValues.vezife
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("vezife") === "Driver" ? (
                    <Form.Item
                      className="ant-form-item"
                      name="Beletcinum"
                      label="Bələtci"
                    >
                      <Select
                        name="Beletcinum"
                        showSearch
                        filterOption={(input, option) => {
                          const label = (option?.label ?? "").toLowerCase();
                          const inputValue = input.toLowerCase();
                          const userId = (option?.value ?? "").toLowerCase();
                          const userName =
                            option?.props.children?.[0]?.toLowerCase() ?? "";

                          return (
                            label.includes(inputValue) ||
                            userId.includes(inputValue) ||
                            userName.includes(inputValue)
                          );
                        }}
                        size="large"
                        placeholder="Bələtci"
                        onChange={handleChange}
                      >
                        {brands.map((brand) => (
                          <Option
                            key={brand.userid}
                            value={brand.userid}
                            label={`${brand.username} ${brand.surename}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>
                                {brand.username} {brand.surename}
                              </span>
                              <span>
                                <FieldNumberOutlined />
                                {brand.userid}
                              </span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="3x4"
                name="filePP"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa 3x4 rəsimini seçin",
                  },
                ]}
                getValueFromEvent={(e) => e.fileList}
              >
                <Upload name="filePP" maxCount={1} listType="picture-card" onPreview={handlePreview}>
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Şəxsiyyət vəsiqəsi"
                name="fileSH"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Zəhmət olmasa Ş.V rəsimini seçin",
                  },
                ]}
              >
                <Upload name="fileSH" maxCount={1} listType="picture-card" onPreview={handlePreview}>
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Row>
            <Space>
              <Button htmlType="reset">Reset</Button>
              <Button htmlType="submit" type="primary" className="ColorMain">
                Əlavə Et
              </Button>
            </Space>
          </div>
        </Card>
      </Form>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <UserIds />
      </Drawer>
      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onCloseCam}
        open={openCam}
      >
        <Webcamcomp />
        <Uplodedimages />
      </Drawer>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default SellingItem;
