import { Layout, theme } from 'antd';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import Settings from '../components/Settings';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import App from '../components/Stats';

const { Content } = Layout;

const Setting = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Siders />
      <Layout>
        <Headers />
        <Content className="site-layout" >
          <App />
          <br />
          <div style={{ padding: 24, minHeight: 380, background: colorBgContainer }}>
            <Settings />
          </div>
        </Content>
        <Footers />
      </Layout>
    </Layout>
  );
};

export default Setting;
