import React from "react";
import { Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
export function getColumnSearchProps(dataIndex) {
  let searchText = "";
  let searchedColumn = null;
  let searchInput = null;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    logToServer(`Search performed on ${dataIndex}: ${selectedKeys[0]}`);
    searchText = selectedKeys[0];
    searchedColumn = dataIndex;
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    searchText = "";
  };
  
  const logToServer = (message) => {
    fetch(`${CONN_KEY}logs/log.php`, {
      method: 'POST',
      body: message,
    })
      .then(response => {
        console.log('Response status:', response.status);
        return response.json();
      })
      .then(data => {
        console.log('Log successful:', data);
      })
      .catch(error => {
        console.error('Error logging to server:', error);
      });
  };

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block", }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, backgroundColor: '#1677ff ' }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownopenChange: (open) => {
      if (open) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    onFilter: (value, record) => {
      const columnValue = record[dataIndex];
      if (typeof columnValue === "undefined" || columnValue === null) {
        return false;
      }
      return columnValue.toString().toLowerCase().includes(value.toLowerCase());
    },
    render: (text) => {
      if (searchedColumn === dataIndex) {
        const searchValue = searchText ? searchText.toString() : "";
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );
      } else {
        return text;
      }
    },
  };
}
