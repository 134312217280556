import React from 'react';

function TooltipAkris() {

    return (
        <div id="unavailable-section" >
            <section>
                <b>Required Rules for ID:</b>
                <p>For confirmation purposes, it is mandatory to provide your current and valid passport number.</p>
            </section>
            <br />
            <section>
                <b>Recently Recorded ID Number</b>
                <br />
                <div className='imsdasd' alt='mg' />
                <br />
                <p>The ID number should be recorded as "ID/Number will be noted 111" as shown below.</p>
            </section>
            <br />
            <section>
                <b>User Information</b>
                <p>Please ensure that your full name and surname are entered correctly in the designated fields.</p>
            </section>
            <br />
            <section>
                <b>Country of Registration and ID or FIN Code</b>
                <p>As part of the verification process, the FIN code must be correctly recorded as indicated in the State Certificate.</p>
            </section>
            <br />
            <section>
                <b>Required Fields</b>
                <p>The following fields are mandatory and should not be left empty: *</p>
                <ul style={{ listStyleType: 'circle', paddingLeft: '25px' }}>
                    <li>ID Number</li>
                    <li>Accreditation Section</li>
                    <li>Department</li>
                    <li>Position</li>
                    <li>Accreditation (e.g., noted as S2)</li>
                </ul>
            </section>
            <br />
            <section>
                <b>Additional Information</b>
                <p>We kindly ask you not to hesitate in providing sensitive personal information, such as the address holder's birthday, country, phone number, education, registration address, and residence address.</p>
            </section>
            <br />
            <section>
                <b>Empty V. Numbers</b>
                <p>By clicking the button located in the upper right corner of the form, you can enter empty V. numbers. This ensures that no ID number remains unfilled.</p>
            </section >
            <br />
            <section>
                <b>Details of the Accreditation Section</b>
                <p>Department: Display the department you are currently in. For example, "Service".</p>
                <ul style={{ listStyleType: 'circle', paddingLeft: '25px' }}>
                    <li>Service - S</li>
                    <li>Marketing - A</li>
                    <li>Kitchen - K</li>
                    <li>Service - T</li>
                    <li>Cashier/Accountant/Technician - N</li>
                </ul>
            </section>
            <br />
            <section>
                <b>Position: </b>
                <p>Ensure accurate job positioning. For example, "Waiter".</p>
                <p>Accreditation: To demonstrate the respective authorization level, all accreditations should be noted as S2.</p>
                <br />
                <ul style={{ listStyleType: 'circle', paddingLeft: '25px' }}>
                    <li>GENERAL MANAGER - <span>S1</span></li>
                    <li>MANAGER - S2</li>
                    <li>BARMAN - S3</li>
                    <li>DJ, SOUND MASTER - A4</li>
                    <li>OFFICE MANAGER - A2</li>
                    <li>KITCHEN MANAGER - K2</li>
                    <li>WAITER - S4</li>
                    <li>MARKETING DIRECTOR - A1</li>
                    <li>PHOTOGRAPHER - A3</li>
                    <li>CASHIER - N2</li>
                    <li>WAITER ASSISTANT - S5</li>
                    <li>HOOKAH ATTENDANT - S4</li>
                    <li>HEAD CHEF - K1</li>
                    <li>TEA MAKER - S6</li>
                    <li>KITCHEN ASSISTANT - K2</li>
                    <li>ATTENDANT - T5</li>
                    <li>ATTENDANT-KITCHEN - K5</li>
                    <li>FRUIT VENDOR - K4</li>
                    <li>KITCHEN SUPERVISOR - K4</li>
                    <li>SALES REPRESENTATIVE - K2</li>
                    <li>GUARD - M2</li>
                    <li>FROM WARDROBE - S1</li>
                    <li>WARDROBE ATTENDANT - N4</li>
                    <li>TECHNICIAN - N3</li>
                    <li>WAITER - S5</li>
                    <li>ACCOUNTANT - N0</li>
                    <li>AUDIT - N12</li>
                    <li>CHEF - K2</li>
                    <li>DISHWASHER - K4</li>
                    <li>SALES MANAGER - S2</li>
                </ul>
            </section>
        </div >
    );
}

export default TooltipAkris;
