import React from "react";
import axios from "axios";
import { InboxOutlined } from '@ant-design/icons';
import { message, Form, Button, Upload, Card, Space } from 'antd';
const { Dragger } = Upload;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const DownloadPdf = () => {

    const [messageApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const file = values.upload[0];

        const formData = new FormData();
        formData.append('file', file.originFileObj); // Use 'originFileObj' to access the actual file object

        try {
            const response = await axios.post(`${CONN_KEY}/pdfUploader.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Handle success
            console.log(response.data);
            messageApi.success('PDF uploaded successfully');

            // Reset the form
            form.resetFields();
        } catch (error) {
            // Handle error
            console.error('PDF upload error', error);
            messageApi.error('Failed to upload PDF');
        }
    };

    const onFinishFailed = () => {
        messageApi.error('Müvafiq sahələr boş qala bilməz!');
    };

    return (
        <div className="container">
            {contextHolder}
            <h1>PDf faylın secin</h1>
            <Form
                form={form}
                onFinish={onFinish}
                encType="multipart/form-data"
                className="form-Add container"
                initialValues={{ remember: true }}
                layout="vertical"
                onFinishFailed={onFinishFailed}
            >
                <Card
                style={{
                    width:"100%",
                }}
                >
                    <Space direction="vertical" style={{ gap: "10",width:"100%" }}>
                        <Form.Item
                            className='ant-form-item'
                            name="upload"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e && e.fileList}
                            rules={[
                                { required: true, message: 'Please upload a file' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value && value[0].type === 'application/pdf') {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Please upload a PDF file'));
                                    },
                                }),
                            ]}
                        >
                            <Dragger
                                name="file"
                                style={{
                                    padding: 24,
                                    width: '100%',
                                    alignItems:"center"
                                }}
                                maxCount={1}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                            </Dragger>
                        </Form.Item>
                        <>
                            <Button htmlType="submit" type="primary" className="ColorMain">
                                Əlavə Et
                            </Button>
                            <Button htmlType="reset">Reset</Button>
                        </>
                    </Space>
                </Card>
            </Form>
        </div>
    );
};

export default DownloadPdf;
