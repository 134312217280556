import React, { useState, useEffect } from "react";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import {
  message,
  Form,
  Input,
  Button,
  Upload,
  Card,
  Row,
  Col,
  Select,
  Space,
  InputNumber,
  Drawer,
} from "antd";
import TooltipAkrisEng from "./Tooltip-akriEng";
// import ImgCrop from 'antd-img-crop';

const { Option } = Select;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

const StaffPersGe = () => {
  const [inputs, setInputs] = useState({}); // Move this line here
  const [brands, setBrands] = useState([]);
  const [open, setOpen] = useState(false);
  const [oneuserid, setuserione] = useState(0);
  const [maxUserId, setMaxUserId] = useState(0);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  axios.get(`${CONN_KEY}StaffGE.php?userid=${inputs.userid}`); // Now you can access 'inputs' variable here
  
  useEffect(() => {
    axios
      .get(CONN_KEY + "StaffGE.php")
      .then((response) => {
        const data = response.data;
        // Find the maximum userid value from the response data
        const maxId = Math.max(...data.map((item) => item.userid));
        const useridone = (maxId + 1).toString().padStart(3, '0');
        setMaxUserId(maxId);
        setBrands(data);
        setuserione(useridone);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm(); // Staff this line to create a form instance

  const onFinish = async (values) => {
    try {
      console.log("Received values of form: ", values);

      const userIdString = values.userid.toString();
      const formattedUserId = userIdString.length <= 3
        ? userIdString.padStart(3, '0')
        : userIdString.slice(0, 6);
      const formData = new FormData();
      formData.append("userid", formattedUserId);
      formData.append("username", values.username);
      formData.append("surename", values.surename);
      formData.append("dadname", values.dadname);
      formData.append("edu", values.edu);
      formData.append("Departament", values.Departament);
      formData.append("recruitment", values.recruitment);
      formData.append("email", values.email);
      formData.append("lokatiogeyd", values.lokatiogeyd);
      // formData.append('lokationW', values.lokationW);
      formData.append("lokationLive", values.lokationLive);
      formData.append("Fin", values.Fin);
      formData.append("datebhrd", values.datebhrd);
      formData.append("Catagory", values.Catagory);
      formData.append("Country", values.Country);
      formData.append("Phone", values.Phone);
      formData.append("vezife", values.vezife);
      formData.append("filePP", values.filePP?.[0]?.originFileObj); // Fix file retrieval
      formData.append("fileSH", values.fileSH?.[0]?.originFileObj); // Fix file retrieval

      console.log("formData:", formData);

      const barcodeResponse = await axios.get(
        `${CONN_KEY}StaffGE.php?userid=${values.userid}`
      );
      if (barcodeResponse.data.status === 1) {
        message.error("Bu istifadəçi artıq yükləmisiniz.");
        return;
      }

      const response = await axios.post(`${CONN_KEY}StaffGE.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === 1) {
        message.success("İstifadəçi uğurla əlavə edildi");
        form.resetFields(); // Reset form fields after successful submission
      } else {
        message.error("Bu istifadəçi artıq yükləmisiniz.");
      }
    } catch (error) {
      console.log(error);
      message.error("An error occurred. Please try again later.");
    }
  };
  const onFinishFailed = () => {
    message.error("The relevant fields cannot be left blank!");
  };
  const handleChange = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onGenderChange = (value) => {
    // Handle the gender change event here
    console.log("Selected Gender:", value);
  };

  const fileList = [];

  return (
    <div className="container">
      {contextHolder}
      <h1>Add new STAFF</h1>
      <Form
        onFinish={onFinish}
        encType="multipart/form-data" // Staff this attribute
        className="form-Staff container"
        initialValues={{ remember: true }}
        layout="vertical"
        onFinishFailed={onFinishFailed}
      >
        <Card
          extra={
            <Button type="default" onClick={showDrawer}>
              Instruction
            </Button>
          }
        >
          <div style={{ gap: "5" }}>
            <Row gutter={[5]}>
              <Form.Item
                className="ant-form-item"
                label="Vn №"
                name="userid"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
                extra={`Last ID- ${maxUserId.toString()}`}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  name="userid"
                  onChange={(value) => handleChange("userid", value)}
                  placeholder={`Last ID ${maxUserId.toString()} +1`}
                  formatter={(value) => `00${value}`.slice(-3)} // Format the number as "002"
                  parser={(value) => value.replace(/\D/g, "")} // Allow only numeric input
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Surname"
                name="surename"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="surename"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="username"
                  size="large"
                  onChange={handleChange}
                  placeholder="Ad"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Father's name"
                name="dadname"
              >
                <Input
                  style={{ width: "100%" }}
                  name="dadname"
                  size="large"
                  onChange={handleChange}
                  placeholder="placeholder"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="ID card NO"
                name="Fin"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="Fin"
                  size="large"
                  onChange={handleChange}
                  placeholder="0000000"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Day of birth"
                name="datebhrd"
              >
                <Input
                  style={{ width: "100%" }}
                  name="datebhrd"
                  size="large"
                  onChange={handleChange}
                  placeholder="00.00.0000"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Country"
                name="Country"
              >
                <Input
                  style={{ width: "100%" }}
                  name="Country"
                  size="large"
                  onChange={handleChange}
                  placeholder="Azərbaycan"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Telephone number"
                name="Phone"
              >
                <Input
                  style={{ width: "100%" }}
                  name="Phone"
                  size="large"
                  onChange={handleChange}
                  placeholder="+994 00 000 00 00"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Education"
                name="edu"
                size="large"
              >
                <Input
                  style={{ width: "100%" }}
                  name="edu"
                  size="large"
                  onChange={handleChange}
                  placeholder="Ali (Unec)"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Date of employment"
                name="recruitment"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="recruitment"
                  size="large"
                  onChange={handleChange}
                  placeholder="00.00.0000"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Registration address"
                name="lokatiogeyd"
                size="large"
              >
                <Input
                  style={{ width: "100%" }}
                  name="lokatiogeyd"
                  size="large"
                  onChange={handleChange}
                  placeholder="Baku"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Address where he lives"
                name="lokationLive"
                size="large"
              >
                <Input
                  style={{ width: "100%" }}
                  name="lokationLive"
                  size="large"
                  onChange={handleChange}
                  placeholder="Baku"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Accreditation"
                name="Catagory"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="Catagory"
                  size="large"
                  onChange={handleChange}
                  placeholder="S3"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Department"
                name="Departament"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
              >
                <Select
                  placeholder="Service"
                  onChange={onGenderChange}
                  allowClear
                  size="large"
                  name="Departament"
                >
                  <Option value="Service">Service</Option>
                  <Option value="Marketing">Marketing</Option>
                  <Option value="Security">Security</Option>
                  <Option value="Kitchen">Kitchen</Option>
                  <Option value="Technician">Technician</Option>
                  <Option value="Housekeeper">Housekeeper</Option>
                  <Option value="Cashier">Cashier</Option>
                  <Option value="Accountant">Accountant</Option>
                  <Option value="Audit">Audit</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="Position"
                name="vezife"
                size="large"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  name="vezife"
                  size="large"
                  onChange={handleChange}
                  placeholder="Barmen"
                />
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="3x4"
                name="filePP"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
                getValueFromEvent={(e) => e.fileList}
              >
                <Upload name="filePP" maxCount={1} listType="picture-card" >
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
              <Form.Item
                className="ant-form-item"
                label="ID cards"
                name="fileSH"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[
                  {
                    required: true,
                    message: "Enter the information",
                  },
                ]}
              >
                <Upload name="fileSH" maxCount={1} listType="picture-card" >
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Row>
            <Space>
              <Button htmlType="reset">Reset</Button>
              <Button htmlType="submit" type="primary" className="ColorMain">
                Add
              </Button>
            </Space>
          </div>
        </Card>
      </Form>
      <Drawer
        title="Təlimat"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <TooltipAkrisEng />
      </Drawer>
    </div>
  );
};

export default StaffPersGe;
