import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/'); // Replace '/' with the desired route to navigate to
    };

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" className='ColorMain' onClick={navigateToHome}>Back Home</Button>}
        />
    );
};

export default NotFound;
