import { Layout, theme } from 'antd';
import Footers from '../components/Footer';
import Headers from '../components/Header';
import Siders from '../components/Sider';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';import AllCardsGE from '../components/Allcardds';
;

const { Content } = Layout;
const CardsGE = () => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();
      
    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            <Siders />
            <Layout>
                <Headers />
                <Content
                    className="site-layout"
                    style={{
                        padding: '0 50px',
                    }}
                >
                    <div
                       style={{
                            padding: 4,
                            minHeight: 380,
                            background: colorBgContainer,
                        }}
                    >
                        <AllCardsGE />
                    </div>
                </Content>
                <Footers />
            </Layout>
        </Layout>
    );
};
export default CardsGE;