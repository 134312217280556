import React, { useState, useEffect } from "react";
import axios from "axios";
import { InboxOutlined } from "@ant-design/icons";
import { message, Form, Button, Upload, List, Card } from "antd";
const { Dragger } = Upload;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const Imageupload = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState([]);
  const [selectedImageSH, setSelectedImageSH] = useState(null);

  useEffect(() => {
    fetchFiles();
  }, []); // Fetch files when the component mounts

  const handleSubmit = () => {
    if (selectedImageSH) {
      const formData = new FormData();
      formData.append("file", selectedImageSH);

      // Make a POST request to uploadimage.php
      axios
        .post(`${CONN_KEY}/uploadimage.php`, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        })
        .then((response) => {
          // Handle the response data
          if (response.data.success) {
            message.success(response.data.message); // Success message
            fetchFiles(); // Refresh the list of uploaded files
          } else {
            message.error(response.data.message); // Error message
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          message.error("Failed to upload image: " + error.message);
        });
    }
  };

  const onFinishFailed = () => {
    messageApi.error("Müvafiq sahələr boş qala bilməz!");
  };

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${CONN_KEY}uploadimage.php`);
      setFileList(response.data.files);
    } catch (error) {
      console.error("Error fetching files", error);
      messageApi.error("Failed to fetch files");
    }
  };
  const generateDownloadUrl = (fileName) => {
    // Create the direct download URL
    return `${CONN_KEY}Images/${fileName}`;
  };

  return (
    <div className="container">
      {contextHolder}
      <Card className="container AFAD">
        <h1>Faylı seçin</h1>
        <br />
        <Dragger
          name="fileSH"
          method="PUT"
          maxCount={1}
          beforeUpload={(file) => {
            setSelectedImageSH(file); // Store the selected image file
            return false; // Prevent upload from happening immediately
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          {selectedImageSH ? (
            <img
              src={URL.createObjectURL(selectedImageSH)}
              style={{ width: 150 }}
              alt="Şəkil"
            />
          ) : null}
        </Dragger>
        <br />
        <Button type="primary" onClick={handleSubmit}>
          Yüklə
        </Button>
      </Card>
    </div>
  );
};

export default Imageupload;
