import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  IdcardOutlined,
  MoreOutlined,
  PlusOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Empty,
  Image,
  Input,
  InputNumber,
  Menu,
  Modal,
  Skeleton,
  Space,
  Table,
  Tabs,
  Tag
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { CONN_KEY } from "../../Conn.js";
import img3 from "../../images/3month.png";
import img6 from "../../images/6manth.png";
import { getColumnSearchProps } from "../utils.js";
const { TabPane } = Tabs;

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

function App() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalopen, setIsModalopen] = useState(false);
  const [isModalopenApp, setisModalopenApp] = useState(false);
  const [isModalopenBalance, setisModalopenBalance] = useState(false);
  const [staffOutReason, setStaffOutReason] = useState("");
  const [App_reasin, setApp_reasin] = useState("");
  const [staffOutDate, setStaffOutDate] = useState("");
  const staffOutReasonInputRef = useRef(null);
  const BlockAppRef = useRef(null);
  const BalanceInputRef = useRef(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const navigate = useNavigate();
  const [pin, setPin] = useState('');
  const [open, setopen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const pinInputRef = useRef();
  const [isModalopenTime, setIsModalopenTime] = useState(false);
  const [Balance, setBalance] = useState("");
  const [activeTab, setActiveTab] = useState(""); // Define activeTab state
  const handleTabChange = (key) => setActiveTab(key); // Define handleTabChange function
  const [checkedList, setCheckedList] = useState(["1", "2", "3", "4", "5", "6", "14", "18", "19"]);
  const [permissions, setPermissions] = useState({});

  const fetchData = () => {
    fetch(`${CONN_KEY}Add.php`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          const updatedData = data.map((item) => ({
            ...item,
            key: item.id,
          }));
          setData(updatedData);
        } else {
          console.warn("No data received from the server");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Fetching data failed:", error);
        setIsLoading(false);
      });
  };

  const showDeleteConfirm = (record) => {
    setItemToDelete(record);
    setopen(true);
  };
  const handleCartButtonClick = (record) => {
    setSelectedRecord(record);
    setIsModalopenTime(true);
  };
  const handleCardClick = async (cardId) => {
    try {
      if (!selectedRecord) {
        console.error("No selected record to associate with the card.");
        return;
      }

      const response = await axios.post(
        `${CONN_KEY}timerbuy.php?userid=${selectedRecord?.userid}&cardId=${cardId}`
      );

      // Handle the API response as needed
      console.log("API Response:", response.data);

      // Close the modal if needed
      setIsModalopenTime(false);
    } catch (error) {
      console.error("Error in handleCardClick:", error);
      // Handle error, show an error message, etc.
    }
  };
  useEffect(() => {
    const isLoggedIn =
      localStorage.getItem("username") !== null;
    //  !== null;
    if (!isLoggedIn) {
      // navigate("/");
    } else {
      console.log("User is logged in.");
      // console.log('Username:', localStorage.getItem('username'));
      // console.log('Level:', localStorage.getItem('s'));
    }
  }, [navigate]);

  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }
  }, []);

  const canSeeSectionsGuidesAz = permissions.GuidesAz === 1;
  const canSeeSectionsGuidesAzBalance = permissions.GuidesAzBalance === 1;
  const canSeeSectionsGuidesAzBlock = permissions.GuidesAzBlock === 1;
  const canSeeSectionsGuidesAzUnblock = permissions.GuidesAzUnblock === 1;
  const canSeeSectionsGuidesAzDelete = permissions.GuidesAzDelete === 1;
  const canSeeSectionsGuidesAzEdit = permissions.GuidesAzEdit === 1;
  const canSeeSectionsGuidesAzBlockApp = permissions.GuidesAzBlockApp === 1;

  const renderActions = (text, record) => {
    const menu = (
      <Menu>
        {canSeeSectionsGuidesAzDelete && (
          <Menu.Item key="delete" onClick={() => showDeleteConfirm(record)}>
            <DeleteOutlined /> Delete
          </Menu.Item>
        )}
        {canSeeSectionsGuidesAzEdit && (
          <Menu.Item key="edit">
            <Link to={`/Update/${record.userid}`}>
              <EditOutlined /> Edit
            </Link>
          </Menu.Item>
        )}
        {canSeeSectionsGuidesAzBalance && (
          <Menu.Item key="Balance" onClick={() => handleBalance(record)}>
            <PlusOutlined /> Balance Update
          </Menu.Item>
        )}
        {canSeeSectionsGuidesAzBlockApp && (
          <Menu.Item key="BlockApp" onClick={() => handleBlockApp(record)}>
            <StopOutlined /> BlockApp
          </Menu.Item>
        )}
        {canSeeSectionsGuidesAzBlock && (
          <Menu.Item key="block" onClick={() => handleBlock(record)}>
            <StopOutlined /> Block
          </Menu.Item>
        )}
        {canSeeSectionsGuidesAzUnblock && (
          <Menu.Item key="unblock" onClick={() => handleUnBlock(record)}>
            <StopOutlined /> Unblock
          </Menu.Item>
        )}
      </Menu>
    );

    if (record && record.key) {
      if (!canSeeSectionsGuidesAz) {
        navigate("/");
        return null;
      }
      return (
        <Dropdown overlay={menu} placement="bottomRight">
          <Button>
            <MoreOutlined />
          </Button>
        </Dropdown>
      );
    }

    return null;
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });
  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    {
      title: "Şəxsiyyət Vəsiqəsi",
      dataIndex: "fileSH",
      key: "20",
      width: 80,
      render: (imagePath, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record.Status && (
            <div className="blockicondfaa dark:bg-slate-800">
              <StopOutlined
                style={{ color: "red", fontSize: 24, position: "absolute", zIndex: '11111' }}
              />
            </div>
          )}
          <Image
            className={`imgpp ${record.Status ? "grayscale" : ""}`}
            src={`${CONN_KEY}scan/${imagePath}`}
            alt="filePP"
            style={{ zIndex: "30" }}
          />
        </div>
      ),
    },
    {
      title: "3x4",
      dataIndex: "filePP",
      key: "1",
      width: 80,
      render: (imagePath, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {record.Status && (
            <div className="blockicondfaa dark:bg-slate-800">
              <StopOutlined
                style={{ color: "red", fontSize: 24, position: "absolute", zIndex: '11111' }}
              />
            </div>
          )}
          <Image
            className={`imgpp ${record.Status ? "grayscale" : ""}`}
            src={`${CONN_KEY}3x4/${imagePath}`}
            alt="filePP"
            style={{ zIndex: "30" }}
          />
        </div>
      ),
    },
    {
      title: "V.Nömrəs",
      dataIndex: "userid",
      key: "2",
      width: 130,
      sorter: {
        compare: (a, b) => {
          const useridA = a.userid != null ? a.userid.toString() : ""; // Handle null or undefined values and convert to string
          const useridB = b.userid != null ? b.userid.toString() : ""; // Handle null or undefined values and convert to string

          // Convert to numbers if possible, fallback to strings otherwise
          const numA = parseFloat(useridA);
          const numB = parseFloat(useridB);

          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB; // Numeric comparison
          }

          return useridA.localeCompare(useridB); // String comparison as fallback
        },
        multiple: 2,
      },
      render: (value, record) => {
        return (
          <>
            <h3 className="bold">{value} </h3>
          </>
        );
      },
      ...getColumnSearchProps("userid"),
    },
    {
      title: "Adı",
      dataIndex: "username",
      width: 130,
      key: "3",
      ...getColumnSearchProps("username"), // Attach the search functionality to this column
    },
    {
      title: "Soy ad",
      width: 150,
      dataIndex: "surename",
      key: "4",
      ...getColumnSearchProps("surename"),
    },
    {
      title: "Doğum günü",
      width: 150,
      dataIndex: "datebhrd",
      key: "26",
      ...getColumnSearchProps("datebhrd"),
    },
    {
      title: "Ata adı",
      dataIndex: "dadname",
      width: 150,
      key: "5",
      ...getColumnSearchProps("dadname"),
    },
    {
      title: "Telefon",
      dataIndex: "Phone",
      width: 150,
      key: "6",
      ...getColumnSearchProps("Phone"),
    },
    {
      title: "Fin",
      dataIndex: "Fin",
      width: 100,
      key: "7",
      ...getColumnSearchProps("Fin"),
    },
    {
      title: "Vəzifəsi",
      dataIndex: "vezife",
      width: 150,
      key: "8",
      sorter: {
        compare: (a, b) => {
          if (a.vezife < b.vezife) {
            return -1;
          }
          if (a.vezife > b.vezife) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "APPaktive",
      key: "9",
      width: 95,
      sorter: (a, b) => {
        const APPaktiveA = a.APPaktive || ""; // Handle null values by providing an empty string
        const APPaktiveB = b.APPaktive || ""; // Handle null values by providing an empty string
        return APPaktiveA.localeCompare(APPaktiveB);
      },
      onFilter: (value, record) => {
        if (value === "red") return record.APPaktive === 0;
        if (value === "green") return record.APPaktive === 1;
        return true;
      },
      render: (text) => (
        text ? <Tag color="green">Registred</Tag> : <Tag color="red">Not yet</Tag>
      ),
    },
    {
      title: "Balans",
      dataIndex: "Balance",
      width: 100,
      key: "10",
      sorter: {
        compare: (a, b) => {
          if (a.Balance < b.Balance) {
            return -1;
          }
          if (a.Balance > b.Balance) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Blok Səbəbi",
      dataIndex: "App_reasin",
      width: 100,
      key: "11",
      sorter: {
        compare: (a, b) => {
          if (a.App_reasin < b.App_reasin) {
            return -1;
          }
          if (a.App_reasin > b.App_reasin) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Blok Open",
      dataIndex: "App_openData",
      width: 100,
      key: "12",
      sorter: {
        compare: (a, b) => {
          if (a.App_openData < b.App_openData) {
            return -1;
          }
          if (a.App_openData > b.App_openData) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "13",
      width: 95,
      sorter: (a, b) => {
        // Handle possible null or undefined values and ensure localeCompare is valid
        const statusA = a.Status || "";
        const statusB = b.Status || "";
        return statusA.localeCompare(statusB);
      },
      onFilter: (value, record) => {
        // Handle filtering for different statuses (if required)
        if (value === "red") {
          return record.Status === "Blocked App" || record.Status === "Block";
        } else if (value === "green") {
          return !record.Status || record.Status === "";
        }
        return true; // Include the record for all other cases
      },
      render: (text) => {
        // Render the status text as a Tag with appropriate color
        let tagColor;
        let statusText;

        // Define tag colors and texts based on status
        if (text === "Block" || text === "Block") {
          tagColor = "red";
          statusText = "Blocked";
        } else if (!text || text === "") {
          tagColor = "green";
          statusText = "Aktiv";
        } else {
          tagColor = "blue"; // Use blue for other statuses, if needed
          statusText = text || "Unknown"; // Provide a fallback for unknown statuses
        }

        return <Tag color={tagColor}>{statusText}</Tag>;
      },
    },
    {
      title: "Bitmə tarixi",
      dataIndex: "endtime",
      width: 120,
      key: "14",
      ...getColumnSearchProps("endtime"),
    },
    {
      title: "ölkə",
      dataIndex: "Country",
      width: 130,
      key: "15",
      sorter: {
        compare: (a, b) => {
          if (a.Country < b.Country) {
            return -1;
          }
          if (a.Country > b.Country) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Blok Sayi",
      dataIndex: "Appcount",
      width: 100,
      key: "16",
      sorter: {
        compare: (a, b) => {
          if (a.Appcount < b.Appcount) {
            return -1;
          }
          if (a.Appcount > b.Appcount) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Blok Ac",
      dataIndex: "AppUnblockAdmin",
      width: 100,
      key: "17",
      sorter: {
        compare: (a, b) => {
          if (a.AppUnblockAdmin < b.AppUnblockAdmin) {
            return -1;
          }
          if (a.AppUnblockAdmin > b.AppUnblockAdmin) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Qeyydiyat tarixi",
      dataIndex: "date_post",
      width: 100,
      key: "22",
      sorter: {
        compare: (a, b) => {
          if (a.date_post < b.date_post) {
            return -1;
          }
          if (a.date_post > b.date_post) {
            return 1;
          }
          return 0;
        },
        multiple: 1,
      },
    },
    {
      title: "Action",
      dataIndex: "userid",
      key: "18",
      width: 50,
      render: (text, record) => (
        <Space>
          {/* <Link to={`/Cart/${record.userid}`} target="_blank"> */}
          <Button
            className={`ColorMain ${record.Status ? "ccolorblock" : ""}`}
            icon={<IdcardOutlined />}
            onClick={() => handleCartButtonClick(record)} // Use the onClick event
          >
            {/* Cart */}
          </Button>
          {/* </Link> */}
          <Link to={`/PDF/${record.userid}`} target="_blank">
            <Button
              className={`ColorMain ${record.Status ? "ccolorblock" : ""}`}
              icon={<FilePdfOutlined />}
            >
              {/* PDF */}
            </Button>
          </Link>
        </Space>
      ),
    },
    {
      title: "More",
      dataIndex: "userid",
      fixed: 'right',
      key: "19",
      render: renderActions,
      width: 50, // Set minimum width to 100 pixels
    },
  ];
  const filteredColumns = columns.filter(column => checkedList.includes(column.key));
  const handleCheckboxChange = checkedValues => {
    setCheckedList(checkedValues);
  };
  const handleDelete = async () => {
    try {
      // Perform PIN validation here
      if (pin === '0102') {
        const response = await axios.delete(`${CONN_KEY}Add.php?userid=${itemToDelete.userid}`);
        if (response.status === 200 && response.data.code === 200) {
          // Handle success, e.g., update the table or show a success message
          Modal.error({
            title: 'Error',
            content: 'Incorrect PIN. Item not deleted.',
          });
        } else {
          // Show an error message from the API response

          Modal.success({
            title: 'Success',
            content: 'Product deleted successfully.',
          });
        }
      } else {
        // Incorrect PIN
        Modal.error({
          title: 'Error',
          content: 'Incorrect PIN. Item not deleted.',
        });
      }
    } catch (error) {
      // Handle error, e.g., show a generic error message
      console.error(error);
      Modal.error({
        title: 'Error',
        content: 'An error occurred while deleting the product.',
      });
    } finally {
      setopen(false);
      setItemToDelete(null);
      setPin(''); // Reset the PIN input
    }
  };
  const handleCancel = () => {
    setopen(false);
    setItemToDelete(null);
    setPin(''); // Reset the PIN input
  };
  const handleUnBlock = (record) => {
    Modal.confirm({
      title: "Diqqət",
      content: "Bu Bələdçini Blokunu qaldırmaq istədiyinizə əminsiniz?",
      okText: "Bloku qaldır",
      okType: "danger",
      cancelText: "Ləğv et",
      onOk: async () => {
        try {
          // Create a FormData object and append data
          const formData = new FormData();
          formData.append("userid", record.userid);

          // Retrieve the username from local storage
          const username = localStorage.getItem('username');

          // Check if the username was found
          if (username) {
            formData.append("username", username);
          } else {
            console.warn("Username not found in local storage.");
          }

          // Make the POST request
          const response = await axios.post(`${CONN_KEY}Unblock.php`, formData);
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      },
    });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // handle table change event and update the state if necessary
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination,
    }));
  };
  const handleBlock = (record) => {
    setSelectedRecord(record);
    setStaffOutReason("");
    setStaffOutDate("");
    setBalance("");
    setIsModalopen(true);
  };
  const handleBlockApp = (record) => {
    setSelectedRecord(record);
    setApp_reasin("");
    setisModalopenApp(true);
  };
  const handleBalance = (record) => {
    setSelectedRecord(record);
    setStaffOutDate("");
    setBalance("");
    setisModalopenBalance(true);
  };
  const handleModalSubmit = async () => {
    try {
      const response = await axios.post(`${CONN_KEY}BlockSeb.php`, {
        userid: selectedRecord.userid,
        BlockSeb: staffOutReason,
        BlockSebdate: staffOutDate,
      });
      console.log(response.data);
      setIsModalopen(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleModalSubmitApp = async () => {
    try {
      const response = await axios.post(`${CONN_KEY}BlockRezerv.php`, {
        userid: selectedRecord.userid,
        App_reasin: App_reasin,
      });
      console.log(response.data);
      setisModalopenApp(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleModalCancel = () => {
    setIsModalopen(false);
  };
  const handleModalCancelApp = () => {
    setisModalopenApp(false);
  };
  const handleChange = (e) => {
    // Access the value from the event object
    const value = e.target.value;
    // Update the Balance state
    setBalance(value);
  };
  const handleModalSubmitBalance = async () => {
    try {
      const response = await axios.post(`${CONN_KEY}BalanceUpdate.php`, {
        userid: selectedRecord.userid,
        Balance: Balance,
      });
      console.log(response.data);
      setisModalopenBalance(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleModalCancelBalance = () => {
    setisModalopenBalance(false);
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : data.length > 0 ? (
        <div className="dark:bg-slate-800">
          <Modal
            title="Confirm Deletion"
            open={open}
            onOk={handleDelete}
            onCancel={handleCancel}
          >
            <div>
              <p> Bu Bələdçini silmək istədiyinizə əminsiniz? Bu əməliyyat geri qaytarıla bilməz. Elementin silinməsini təsdiqləmək üçün PİN kodunuzu daxil edin:</p>
              <Input
                ref={pinInputRef}
                type="password"
                className="dark:bg-slate-800"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </div>
          </Modal>
          <Checkbox.Group
            value={checkedList}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignContent: 'center',
            }}
            onChange={handleCheckboxChange}
          >
            {columns.map(column => (
              <Checkbox key={column.key} value={column.key}>
                {column.title}
              </Checkbox>
            ))}
          </Checkbox.Group>
          <Table
            columns={filteredColumns}
            dataSource={data}
            loading={isLoading}
            pagination={{
              pageSize: 8,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            locale={{
              emptyText: (
                <Empty
                  description="No Data"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ marginTop: 50 }}
                />
              ),
            }}
            rowClassName={(record) => (record.Status ? 'disabled' : '')}
          />
          <Modal
            title="Block app"
            open={isModalopenApp}
            onOk={handleModalSubmitApp}
            onCancel={handleModalCancelApp}
            destroyOnClose
          >
            <Space>
              <Input
                ref={BlockAppRef}
                placeholder="Reason"
                name="App_reasin"
                value={App_reasin}
                onChange={(e) => setApp_reasin(e.target.value)}
              />
            </Space>
          </Modal>
          <Modal
            title="Block"
            open={isModalopen}
            onOk={handleModalSubmit}
            onCancel={handleModalCancel}
            destroyOnClose
          >
            <Space>
              <Input
                ref={staffOutReasonInputRef}
                placeholder="Reason"
                name="BlockSeb"
                value={staffOutReason}
                onChange={(e) => setStaffOutReason(e.target.value)}
              />
              <Input
                placeholder="Date"
                name="BlockSebdate"
                value={staffOutDate}
                onChange={(e) => setStaffOutDate(e.target.value)}
              />
            </Space>
          </Modal>
          <Modal
            title="Balans Update"
            open={isModalopenBalance}
            onOk={handleModalSubmitBalance}
            onCancel={handleModalCancelBalance}
            destroyOnClose
          >
            <Space>
              <InputNumber
                ref={BalanceInputRef}
                placeholder="500"
                name="Məbləğ"
                value={Balance}
                onChange={handleChange}
              />
            </Space>
          </Modal>
          <Modal
            title="Kartın müddətini seçin"
            open={isModalopenTime}
            onCancel={() => setIsModalopenTime(false)}
            destroyOnClose
            footer={null}
          >
            <div className="flex gap-2">
              <Link to={`/Cart/${selectedRecord?.userid}`}>
                <Card
                  className="flex hover:bg-blue-200"
                  hoverable
                  onClick={() => handleCardClick(3)}
                >
                  <img src={img3} alt="Card 3" />
                </Card>
              </Link>
              <Link to={`/Cart/${selectedRecord?.userid}`}>
                <Card
                  hoverable
                  className="flex hover:bg-blue-200"
                  onClick={() => handleCardClick(6)}
                >
                  <img src={img6} alt="Card 6" />
                </Card>
              </Link>
            </div>
            <br />
            <Link to={`/Cart/${selectedRecord?.userid}`}>
              <Button
                // className={`ColorMain`}
                icon={<IdcardOutlined />}
              >
                Cart-{selectedRecord?.userid}
              </Button>
            </Link>
          </Modal>
        </div>
      ) : (
        <div>
          <p>No data found.</p>
          <Empty />
          <Button type="primary" className="ColorMain dark:bg-slate-900">
            <Link to="/add">Add</Link>
          </Button>
        </div>
      )}
    </>
  );
}
export default App;
