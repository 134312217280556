import { Layout, theme } from "antd";
import Footers from "../components/Footer";
import Headers from "../components/Header";
import Siders from "../components/Sider";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TicTacToeGame from "../components/TicTak";
import NotFound from "../components/notfound";

const { Content } = Layout;

const Tictak = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in (replace with your own logic)
    const isLoggedIn = localStorage.getItem("username") !== null;

    // Get the level value from localStorage
    const level = localStorage.getItem("s");

    if (
      !isLoggedIn ||
      (level !==
        "$2y$10$1D1qlTbxd9vUbu1XXLaTiecrENy.vK85CI4dWtgzuSxmi.EUrVgSG" &&
        level !==
          "$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy")
    ) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Siders />
      <Layout>
        <Headers />
        <Content
          className="site-layout"
          style={{
            padding: "0 10px",
          }}
        >
          <div
            style={{
              padding: 4,
              minHeight: 380,
              background: colorBgContainer,
            }}
          >
            <h1>Not found</h1>
            <NotFound />
            <TicTacToeGame />
          </div>
        </Content>
        <Footers />
      </Layout>
    </Layout>
  );
};

export default Tictak;
