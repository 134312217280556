import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import axios from "axios";

const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;

const Passchange = () => {
  const [form] = Form.useForm();
  const [id, setId] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem("_i_");
    if (userId) {
      setId(userId);
      form.setFieldsValue({ id: userId });
    }
  }, []);

  const onFinish = async (values) => {
    try {
      console.log("Form values:", values);

      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("newPassword", values.newPassword);

      const response = await axios.post(`${CONN_KEY}Passreset.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response from backend:", response);
      console.log("Response data from backend:", response.data);

      if (response.data.status === 1) {
        // Display updated success message
        message.success(response.data.message);

        // Navigate to the home page
        window.location.replace("/");
      } else {
        // Display error message
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <h3>Parolu yenilə</h3>
      <Form
        form={form}
        name="password_reset"
        className="login-form"
        onFinish={onFinish}
      >
        <Form.Item name="id" initialValue={id} hidden>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="newPassword"
          style={{ minWidth: "191px" }}
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["newPassword"]}
          style={{ minWidth: "191px" }}
          rules={[
            {
              required: true,
              message: "Please confirm your new password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The two passwords do not match.");
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Confirm New Password"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Yenilə
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Passchange;
