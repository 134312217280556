
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { message, Form, Input, Button, Upload, Card, Row, Col, Select, Space } from 'antd';
import { FieldNumberOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FilePdfOutlined, IdcardOutlined } from '@ant-design/icons';

const { Option } = Select;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const Update = () => {
    const [data, setData] = useState({});
    const [brands, setBrands] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageSH, setSelectedImageSH] = useState(null);
    const { UserId } = useParams();

    useEffect(() => {
        // Fetch brand data from the server
        const fetchBrands = async () => {
            try {
                const response = await axios.get(CONN_KEY + 'AddGE.php');
                setBrands(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchBrands();
    }, []);

    useEffect(() => {
        // Fetch user data based on UserId
        const fetchData = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}AddGE.php?userid=${UserId}`);
                setData(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [UserId]);

    const onGenderChange = (value) => {
        form.setFieldsValue({
            Beletcinum: undefined,
        });
    };

    const onFinish = async (values) => {
        try {
            const response = await axios.put(`${CONN_KEY}updateGE.php`, values, {
                params: {
                    userid: UserId,
                },
            });

            if (response.data.status === 0) {
                message.error(response.data.message);
                return;
            }

            message.success(response.data.message);
        } catch (error) {
            console.error(error);
        }
    };

    const [form] = Form.useForm();

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            form.setFieldsValue(data);
        }
    }, [form, data]);

    const handleSubmitPP = () => {
        if (selectedImage) {
            const formData = new FormData();
            formData.append('file', selectedImage);

            // Make a POST request to updateimageStaffPP.php
            fetch(`${CONN_KEY}Upgm34GE.php?userid=${UserId}`, {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    // Handle the response data
                    if (data.success) {
                        message.success(data.message); // Success message
                    } else {
                        message.error(data.message); // Error message
                    }
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    message.error(error);
                });
        }
    };

    const handleSubmit = () => {
        if (selectedImageSH) {
            const formData = new FormData();
            formData.append('file', selectedImageSH);

            // Make a POST request to USHimg.php
            fetch(`${CONN_KEY}USHimgGE.php?userid=${UserId}`, {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    // Handle the response data
                    if (data.success) {
                        message.success(data.message); // Success message
                    } else {
                        message.error(data.message); // Error message
                    }
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    message.error(error);
                });
        }
    };
    return (
        <div className="container">
            <h1>Vn №{UserId}</h1>
            <Form
                form={form}
                onFinish={onFinish}
                encType="multipart/form-data"
                className="form-Add container"
                layout="vertical"
            >
                <Card>
                    <div style={{ gap: '10px' }}>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Vn №" name="userid">
                                    <Input style={{ width: '100%' }} size="large" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Name" name="username">
                                    <Input style={{ width: '100%' }} size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Surname" name="surename">
                                    <Input style={{ width: '100%' }} size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Father's name" name="dadname">
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.dadname}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Personal NO" name="Fin">
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.Fin}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Day of birth" name="datebhrd">
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.datebhrd}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Country" name="Country">
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.Country}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Phone number" name="Phone">
                                    <Input
                                        style={{ width: '100%' }}
                                        size="large"
                                        placeholder={data.Phone}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item' label="Position" name="vezife" size="large">
                                    <Select
                                        placeholder={data.vezife}
                                        onChange={onGenderChange}
                                        allowClear
                                        size="large"
                                    >
                                        <Option value="Tour Guide">Tour Guide</Option>
                                        <Option value="Driver">Driver</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className='ant-form-item'
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.vezife !== currentValues.vezife}
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('vezife') === 'Driver' ? (
                                            <Form.Item
                                                className='ant-form-item' name="Beletcinum" label="Bələtci">
                                                <Select size="large" showSearch placeholder={data.Beletcinum}>
                                                    {brands.map((brand) => (
                                                        <Option key={brand.userid} value={brand.userid}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <span>{brand.username} {brand.surename}</span>
                                                                <span><FieldNumberOutlined />{brand.userid}</span>
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        ) : null
                                    }
                                </Form.Item>
                            </Col>
                            <Form.Item
                                className='ant-form-item'
                                label="3x4 Photo"
                                name="filePP"
                                disabled
                                getValueFromEvent={(e) => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    const fileList = e && e.fileList;
                                    if (fileList) {
                                        return fileList.slice(-1); // Limit the fileList to the last selected file
                                    }
                                    return [];
                                }}
                            >
                                <Upload
                                    name="filePP"
                                    method="PUT"
                                    maxCount={1}
                                    listType="picture"
                                    beforeUpload={(file) => {
                                        setSelectedImage(file); // Store the selected image file
                                        return false; // Prevent upload from happening immediately
                                    }}
                                >
                                    {selectedImage ? (
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            style={{ width: 50 }}
                                            alt="3x4 Photo"
                                        />
                                    ) : data.filePP ? (
                                        <img
                                            src={`${CONN_KEY}/3x4GE/${data.filePP}`}
                                            style={{ width: 50 }}
                                            alt="3x4 Photo"
                                        />
                                    ) : null}
                                </Upload>
                                <Button type="primary" onClick={handleSubmitPP}>
                                    Update
                                </Button>
                            </Form.Item>
                            <Form.Item
                                className='ant-form-item'
                                label="ID cards Photo"
                                name="fileSH"
                                disabled
                                getValueFromEvent={(e) => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    const fileList = e && e.fileList;
                                    if (fileList) {
                                        return fileList.slice(-1); // Limit the fileList to the last selected file
                                    }
                                    return [];
                                }}
                            >
                                <Upload
                                    name="fileSH"
                                    method="PUT"
                                    maxCount={1}
                                    listType="picture"
                                    beforeUpload={(file) => {
                                        setSelectedImageSH(file); // Store the selected image file
                                        return false; // Prevent upload from happening immediately
                                    }}
                                >
                                    {selectedImageSH ? (
                                        <img
                                            src={URL.createObjectURL(selectedImageSH)}
                                            style={{ width: 150 }}
                                            alt="ID cards Photo"
                                        />
                                    ) : data.fileSH ? (
                                        <img
                                            src={`${CONN_KEY}scanGE/${data.fileSH}`}
                                            style={{ width: 150 }}
                                            alt="ID cards Photo"
                                        />
                                    ) : null}
                                </Upload>
                                <Button type="primary" onClick={handleSubmit}>
                                Update
                                </Button>
                            </Form.Item>
                        </Row>
                        <Space>
                            <Link to={`/CartGE/${data.userid}`} target='_blank'>
                                <Button icon={<IdcardOutlined />}>
                                    Cart
                                </Button>
                            </Link>
                            <Link to={`/PDFGE/${data.userid}`} target='_blank'>
                                <Button icon={<FilePdfOutlined />}>
                                    PDF
                                </Button>
                            </Link>
                            <Button htmlType="submit"
                                type="primary" className="ColorMain">
                                Update
                            </Button>
                        </Space>
                    </div>
                </Card>
            </Form>
        </div >
    );
};

export default Update;
