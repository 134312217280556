import React from 'react';

function TooltipAkris() {

    return (
        <div id="unavailable-section" >
            <section>
                <b>Vəsiqə Tələb olunan qaydalar :</b>
                <p>Təsdiqləmə məqsədilə cari və etibarlı pasport nömrənizi təqdim etmək məcburidir.</p>
            </section>
            <br />
            <section>
                <b>Son qeydə alınmış vəsiqə nömrəsi</b>
                <div className='imsdasd' alt='mg' />
                <p>Son aşağıda göstərildiyi kimi "V/N qeyd olunacaq 111" qeyd edilməlidir</p>
            </section>
            <br />
            <section>
                <b>İstifadəçi Məlumatı</b>
                <p>Zəhmət olmasa, tam adınızın və soyadınızın təyin olunmuş sahələrə düzgün daxil edilməsinə əmin olun.</p>
            </section>
            <br />
            <section>
                <b>Qeydiyatda olduğu Ölkənin vəsiqə nömrəsi və yaxud FİN kodu </b>
                <p>Doğrulama prosesinin bir hissəsi kimi, Fin kodu Dövlət tərəfindən verilmiş Sertifikatda düzgün qeyd edilməlidir.</p>
            </section>
            <br />
            <section>
                <b>Tələb olunan sahələr</b>
                <p>Aşağıdakı sahələr məcburidir və boş qalmamalıdır: *</p>
                <ul style={{ listStyleType: 'circle', paddingLeft: '25px' }}>
                    <li>Vəsiqə Nömrəsi</li>
                    <li>Akkreditasiya Hissəsi</li>
                    <li>Şöbə</li>
                    <li>Mövqe</li>
                    <li>Akkreditasiya (misal: S2 kimi qeyd olunur)</li>
                </ul>
            </section>
            <br />
            <section>
                <b>Əlavə Məlumat</b>
                <p>Xahiş edirik formada ünvan sahibinin doğum günü, ölkəsi, telefon nömrəsi, təhsili, qeydiyyat ünvanı və yaşayış ünvanı kimi həssas şəxsi məlumatlarınızı verməkdən çəkinməyin.</p>
            </section>
            <br />
            <section>
                <b>Boş V. Rəqəmlər</b>
                <p>Formanın yuxarı sağ hissəsində yerləşən düyməni klikləməklə boş V. nömrələrinə daxil ola bilərsiniz. Bu, heç bir ID nömrəsinin doldurulmamış qalmamasını təmin edir.</p>
            </section >
            <br />
            <section>
                <b>Akkreditasiya Hissəsinin Təfərrüatları</b>
                <p>Departament: Hazırda daxil olduğunuz şöbəni göstərin. Məsələn, "Xidmət".</p>
                <ul style={{ listStyleType: 'circle', paddingLeft: '25px' }}>
                    <li>Service - S</li>
                    <li>Marketing - A</li>
                    <li>Mətbəx - K</li>
                    <li>Xadimə - T</li>
                    <li>Kassir/Mühasib/Texnik - N</li>
                </ul>
            </section>
            <br />
            <section>
                <b>Mövqe: </b>
                <p>Dəqiq iş mövqeyinizi təmin edin. Məsələn, "Ofisant".</p>
                <p>Akkreditasiya: Müvafiq icazə səviyyəsini göstərmək üçün bütün akkreditasiyalar S2 kimi qeyd edilməlidir.</p>
                <br />
                <ul style={{ listStyleType: 'circle', paddingLeft: '25px' }}>
                    <li>GENERAL MANAGER - <span>S1</span></li>
                    <li>MANAGER - S2</li>
                    <li>BARMEN - S3</li>
                    <li>DJ, SOUND MASTER - A4</li>
                    <li>OFICE MANAGER - A2</li>
                    <li>MƏTBƏX MANAGER - K2</li>
                    <li>OFISIANT - S4</li>
                    <li>MARKETING DIRECTOR - A1</li>
                    <li>PHOTOGRAPHER - A3</li>
                    <li>KASSIR - N2</li>
                    <li>OFISIANT KÖMƏKÇISI - S5</li>
                    <li>QƏLYANÇI - S4</li>
                    <li>BAŞ AŞBAZ - K1</li>
                    <li>ÇAYÇI - S6</li>
                    <li>AŞBAZ KÖMƏKÇISI - K2</li>
                    <li>XADIMƏ - T5</li>
                    <li>XADIMƏ-MƏTBƏX	- K5</li>
                    <li>MEYVƏÇI	- K4</li>
                    <li>MƏTBƏX NƏZARƏTÇISI	- K4</li>
                    <li>SATIŞ TƏMSILÇISI - K2</li>
                    <li>MÜHAFIZƏÇI - M2</li>
                    <li>ANBARDAN - S1</li>
                    <li>QARDEROBÇU - N4</li>
                    <li>TEXNIK - N3</li>
                    <li>FIŞƏNGÇI - S5</li>
                    <li>MÜHASIB - N0</li>
                    <li>AUDIT - N12</li>
                    <li>AŞBAZ - K2</li>
                    <li>QABYUYAN - K4</li>
                    <li>SATIŞ MENECERI - S2</li>
                </ul>
            </section>
        </div >
    );
}

export default TooltipAkris;
