import { Button, Space, Empty, Skeleton, Table, Input, Modal, Tag, Menu, Dropdown, Select, Checkbox, Image } from 'antd';
import { Link } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import { IdcardOutlined, SearchOutlined, LogoutOutlined, DeleteOutlined, MoreOutlined, FilePdfOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
function StafTableGe() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [isModalopen, setIsModalopen] = useState(false);
    const [staffOutReason, setStaffOutReason] = useState('');
    const [staffOutDate, setStaffOutDate] = useState('');
    const staffOutReasonInputRef = useRef(null);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [statusFilter, setStatusFilter] = useState('green');
    const [dropdownValues, setDropdownValues] = useState([]);
    const [dropdownValuesVezife, setDropdownValuesVezife] = useState([]);

    const filterDropdown = (values) => { };

    const navigate = useNavigate();
    useEffect(() => {
        const isLoggedIn = localStorage.getItem('username') !== null;
        if (!isLoggedIn) {
            navigate('/');
        } else {
            console.log('User is logged in.');
        }
    }, [navigate]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 15,
        },
    });
    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 10000);
        return () => clearInterval(interval);
    }, []);
    const fetchData = () => {
        axios.get(CONN_KEY + 'StaffGE.php')
            .then(response => {
                // Add a unique "key" prop to each item
                const updatedData = response.data.map(item => ({ ...item, key: item.id }));
                setData(updatedData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        logToServer(`Search performed on Staff Geo ${dataIndex}: ${selectedKeys[0]}`);
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const logToServer = (message) => {
        fetch(`${CONN_KEY}logs/log.php`, {
            method: 'POST',
            body: message,
        })
            .then(response => {
                console.log('Response status:', response.status);
                return response.json();
            })
            .then(data => {
                console.log('Log successful:', data);
            })
            .catch(error => {
                console.error('Error logging to server:', error);
            });
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        surename="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        surename="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        surename="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        surename="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),

        onFilterDropdownOpenChange: (open) => {
            if (open) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },

        onFilter: (value, record) => {
            const columnValue = record[dataIndex];
            if (typeof columnValue === 'undefined' || columnValue === null) {
                return false; // Return false or a default value if the column value is undefined or null
            }
            // Convert both the filter value and the column value to lowercase for case-insensitive comparison
            return columnValue.toString().toLowerCase().includes(value.toLowerCase());
        },
        render: (text) => {
            if (typeof text === 'undefined' || text === null) {
                return null; // Return null or a default value if the text is undefined or null
            }

            // Convert the `searchText` to lowercase for case-insensitive highlighting
            const searchValue = searchText ? searchText.toLowerCase() : '';

            // Perform case-insensitive highlighting only if `text` is not `undefined` or `null`
            const highlightedText = (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchValue]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            );

            return <span>{highlightedText}</span>;
        },
    });
    useEffect(() => {
        axios
            .get(CONN_KEY + 'StaffGE.php')
            .then(response => {
                // Extract custom job titles from each staff member
                const vezifeValues = response.data.map(item => item.vezife);

                // Remove duplicate job titles using Set
                const uniqueVezifeValues = [...new Set(vezifeValues)];

                // Update dropdown values
                setDropdownValuesVezife(uniqueVezifeValues);

                // Call the filterDropdown function passing the dropdown values as an argument
                filterDropdown(uniqueVezifeValues);

                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    }, []);
    const getColumnVezifeFilterProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                {dataIndex === 'vezife' && (
                    <Select
                        ref={searchInput}
                        placeholder={`Filter by ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(value) => setSelectedKeys(value ? [value] : [])}
                        onPressEnter={confirm}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    >
                        {dropdownValuesVezife.map((value) => (
                            <Option key={value} value={value}>
                                {value}
                            </Option>
                        ))}
                    </Select>
                )}
                <Space>
                    <Button
                        type="primary"
                        onClick={confirm}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) => {
            return record[dataIndex] === value;
        },
        render: (text) => {
            const tagColor = text === 'General Manager' ? 'red' : 'green';
            return <>{text.toUpperCase()}</>;
        },
    });
    useEffect(() => {
        axios
            .get(CONN_KEY + 'StaffGE.php')
            .then(response => {
                // Extract custom job titles from each staff member
                const DepartamentValues = response.data.map(item => item.Departament);

                // Remove duplicate job titles using Set
                const uniqueDepartamentValues = [...new Set(DepartamentValues)];

                // Display unique job titles
                // console.log(uniqueDepartamentValues);

                // Update dropdown values
                setDropdownValues(uniqueDepartamentValues);

                // Call the filterDropdown function passing the dropdown values as an argument
                filterDropdown(uniqueDepartamentValues);

                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    }, []);
    const getColumnFilterProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                {dataIndex === 'Departament' && (
                    <Select
                        ref={searchInput}
                        placeholder={`Filter by ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(value) => setSelectedKeys(value ? [value] : [])}
                        onPressEnter={confirm}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    >
                        {dropdownValues.map((value) => (
                            <Option key={value} value={value}>
                                {value}
                            </Option>
                        ))}
                    </Select>
                )}
                <Space>
                    <Button
                        type="primary"
                        onClick={confirm}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) => {
            if (dataIndex === 'Departament') {
                return record[dataIndex] === value;
            }
            return record[dataIndex] === value;
        },
        render: (text) => {
            const tagColor = text === 'General Manager' ? 'red' : 'green';
            return <Tag color={tagColor}>{text.toUpperCase()}</Tag>;
        },
    });
    // const getColumnSWorkProps = (dataIndex) => {
    //     const dropdownValues = ["Ellips", "Extra", "Sahara"];
    //     return {
    //         filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //             <div style={{ padding: 8 }}>
    //                 <Select
    //                     ref={searchInput}
    //                     allowClear
    //                     placeholder={`Filter by ${dataIndex}`}
    //                     value={selectedKeys[0]} // Access the first selected key
    //                     onChange={(value) => setSelectedKeys(value ? [value] : [])} // Wrap single value in an array
    //                     onSearch={(value) => {
    //                     }}
    //                     onPressEnter={confirm}
    //                     style={{ width: 188, marginBottom: 8, display: 'block' }}
    //                 >
    //                     {dropdownValues.map((value) => (
    //                         <Option key={value} value={value}>
    //                             {value}
    //                         </Option>
    //                     ))}
    //                 </Select>
    //                 <Space>
    //                     <Button
    //                         type="primary"
    //                         onClick={confirm}
    //                         icon={<SearchOutlined />}
    //                         size="small"
    //                         style={{ width: 90 }}
    //                     >
    //                         Search
    //                     </Button>
    //                     <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
    //                         Reset
    //                     </Button>
    //                 </Space>
    //             </div>
    //         ),
    //         onFilter: (value, record) => {
    //             const dataIndexValue = record[dataIndex];
    //             return (
    //                 dataIndexValue &&
    //                 value &&
    //                 dataIndexValue.toUpperCase() === value.toUpperCase()
    //             );
    //         },
    //         render: (text) => {
    //             let tagColor;
    //             switch (text) {
    //                 case "Extra":
    //                     tagColor = "#f7c66e";
    //                     break;
    //                 case "Ellips":
    //                     tagColor = "#ffa5ff";
    //                     break;
    //                 case "Sahara":
    //                     tagColor = "#7fb3db";
    //                     break;
    //                 default:
    //                     tagColor = "green";
    //                     break;
    //             }
    //             return <Tag color={tagColor}>{text.toUpperCase()}</Tag>;
    //         },
    //     };
    // };
    const handleOut = (record) => {
        setSelectedRecord(record);
        setStaffOutReason('');
        setStaffOutDate('');
        setIsModalopen(true);
    };
    const handleModalSubmit = async () => {
        try {
            const response = await axios.post(`${CONN_KEY}StaffOutGe.php`, {
                id: selectedRecord.id,
                outwhy: staffOutReason,
                outdate: staffOutDate,
            });
            console.log(response.data);
            setIsModalopen(false);
        } catch (error) {
            console.error(error);
        }
    };
    const handleModalCancel = () => {
        setIsModalopen(false);
    };
    const renderActions = (text, record) => {
        const userLevel = localStorage.getItem('s');
        if (record && record.key && (userLevel === '$2y$10$4xzVjYy2YQ81h1UnMmpWkOML81ONADMMkttuE7OMg1dGQ.x.wd8xy' || userLevel === '$2y$10$DwRZj.6O0zGTv6..K1BbxeytJkV4jklbXs86ROaiUqLi8jfECHO4C' || userLevel === 'e8cf599ed55d6997e43e63998ec4020ca2cb3fbd')) {
            const menu = (
                <Menu>
                    <Menu.Item key="edit">
                        <Link to={`/UpdateStaff-ge/${record.id}`}>
                            <EditOutlined /> Edit
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="Out" onClick={() => handleOut(record)}>
                        <LogoutOutlined /> Resignation
                    </Menu.Item>
                    <Menu.Item key="delete" onClick={() => handleDelete(record)}>
                        <DeleteOutlined /> Delete
                    </Menu.Item>
                </Menu>
            );

            return (
                <Space>
                    <Dropdown overlay={menu} placement="bottomRight">
                        <Button> <MoreOutlined /></Button>
                    </Dropdown>
                </Space>
            );
        }
        return null;
    };
    const columns = [
        {
            title: '3x4',
            dataIndex: 'filePP',
            key: '1',
            width: 100,
            render: (imagePath) => <Image className='imgpp' src={`${CONN_KEY}/StaffGe/3x4Staff/${imagePath}`} alt="filePP" />,
        },
        {
            title: 'Voucher number',
            dataIndex: 'userid',
            key: '1',
            width: 150,
            sorter: {
                compare: (a, b) => b.userid.localeCompare(a.userid),
                multiple: 1,
            },
            render: (value, record) => {
                const { Catagory } = record; // Assuming `Catagory` is available in the `record` object
                return (
                    <>
                        <h3 className='bold'>
                            {value} {record.Catagory} {/* Updated: Accessing 'Catagory' directly from 'record' */}
                        </h3>
                    </>
                );
            },
            ...getColumnSearchProps('userid'), // Assuming `getColumnSearchProps` is defined elsewhere
        },
        {
            title: 'Username',
            dataIndex: 'username', width: 150,
            key: '2',
            ...getColumnSearchProps('username'),
        },
        {
            title: 'Surname',
            dataIndex: 'surename', width: 150,
            key: '2',
            ...getColumnSearchProps('surename'),
        },
        {
            title: 'Fathers name',
            dataIndex: 'dadname', width: 150,
            key: '2',
            ...getColumnSearchProps('dadname'),
        },
        {
            title: 'Fin',
            dataIndex: 'Fin', width: 150,
            key: '756',
            ...getColumnSearchProps('Fin'),

        },
        {
            title: 'Position',
            dataIndex: 'vezife',
            width: 150,
            key: '3',
            ...getColumnVezifeFilterProps('vezife'),
        },
        {
            title: 'Department',
            dataIndex: 'Departament',
            width: 150,
            key: '75654',
            ...getColumnFilterProps('Departament'),
        },
        {
            title: 'Accreditation',
            dataIndex: 'Catagory', width: 120,
            key: '75654',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: '9',
            width: 95,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Checkbox
                        checked={selectedKeys.includes('red')}

                        onChange={() =>
                            setSelectedKeys(selectedKeys.includes('red') ? [] : ['red'])
                        }
                    >
                        It doesn't work
                    </Checkbox>
                    <Checkbox
                        checked={selectedKeys.includes('green')}
                        onChange={() => {
                            setSelectedKeys(selectedKeys.includes('green') ? [] : ['green']);
                            confirm();
                            setStatusFilter('green');
                        }}
                    >
                        It works
                    </Checkbox>
                    <div style={{ marginTop: 8 }}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                confirm();
                                setStatusFilter('green');
                            }}
                            style={{ marginRight: 8 }}
                        >
                            Filter
                        </Button>
                        <Button size="small" onClick={clearFilters}>
                            Reset
                        </Button>
                    </div>
                </div>
            ),
            onFilter: (value, record) => {
                if (statusFilter === null) {
                    return true; // No filter applied, show all records
                }
                return record.Status ? value === 'red' : value === 'green';
            },
            render: (text) => (
                <Tag color={text ? 'red' : 'green'}>
                    {text ? 'It is not works' : 'It works'}
                </Tag>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'userid',
            key: 'x',
            width: 50,
            render: (text, record) => (
                <Space>
                    <Link to={`/CardPersonal-Ge/${encodeURIComponent(record.id)}`} target='_blank'>
                        <Button className='ColorMain' icon={<IdcardOutlined />} >
                            {/* Cart */}
                        </Button>
                    </Link>
                    <Link to={`/PDFStaff-Ge/${record.id}`} target='_blank'>
                        <Button className='ColorMain' icon={<FilePdfOutlined />}>
                            {/* Cart */}
                        </Button>
                    </Link>
                </Space>
            ),
        },
        {
            title: 'More',
            dataIndex: 'userid',
            key: '256321',
            render: renderActions,
            width: 80, // Set minimum width to 100 pixels
        },
    ];
    const handleDelete = (record) => {
        Modal.confirm({
            title: 'Diqqət',
            content: 'Bu Bələdçini silmək istədiyinizə əminsiniz? Bu əməliyyat geri qaytarıla bilməz.',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'Ləğv et',
            onOk: async () => {
                try {
                    const response = await axios.delete(`${CONN_KEY}StaffGE.php?userid=${record.userid}`);
                    console.log(response.data); // You can log the response data to see the server's response
                    // Handle success, e.g., update the table or show a success message
                    // Update the table or perform any necessary actions
                } catch (error) {
                    // Handle error, e.g., show an error message
                    console.error(error);
                }
            },
        });
    };
    const handleTableChange = (pagination, filters, sorter) => {
        // handle table change event and update the state if necessary
        setTableParams((prevParams) => ({
            ...prevParams,
            pagination,
        }));
    };
    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : data.length > 0 ? (
                <>
                    <Modal
                        title="Resignation"
                        open={isModalopen}
                        onOk={handleModalSubmit}
                        onCancel={handleModalCancel}
                        destroyOnClose
                    >
                        <Input
                            ref={staffOutReasonInputRef}
                            placeholder="Reason"
                            name='outwhy'
                            value={staffOutReason}
                            onChange={(e) => setStaffOutReason(e.target.value)}
                        />
                        <br />
                        <br />
                        <Input
                            placeholder="Date"
                            name='outdate'
                            value={staffOutDate}
                            onChange={(e) => setStaffOutDate(e.target.value)}
                        />
                    </Modal>
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={isLoading}
                        onChange={handleTableChange}
                        scroll={{ y: 650, x: 'max-content' }}
                        summary={() => (
                            <Table.Summary fixed='top'>
                            </Table.Summary>
                        )}
                        pagination={{
                            ...tableParams.pagination,
                            showSizeChanger: true,
                            showTotal: (total) => `Total ${total} items`,
                            pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200', '500', '800'],
                        }}
                        locale={{
                            emptyText: (
                                <Empty
                                    description="No Data"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    style={{ marginTop: 50 }}
                                />
                            ),
                        }}
                    />

                </>
            ) : (
                <div>
                    <Skeleton active />
                </div>
            )}
        </>
    );
}

export default StafTableGe;