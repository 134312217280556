import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    const trackingId = 'G-W5HYH32Y3F';

    // Include the Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(script);

    script.onload = () => {
      // Initialize Google Analytics
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', trackingId);

      // Track PDF link view event
      if (window.location.pathname.endsWith('/menu/menu.pdf')) {
        gtag('event', 'pdf_view', {
          event_category: 'PDF',
          event_label: 'menu/menu.pdf',
        });
      }
    };
  }, []);

  return null;
};

export default GoogleAnalytics;
