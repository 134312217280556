import { Button, Input, Layout, Space, theme } from "antd";
import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import Footers from "../components/Footer";
import Headers from "../components/Header";
import Siders from "../components/Sider";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const videoConstraints = {
  width: 300,
  height: 400,
  facingMode: "user",
};

const Webcamc = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [facingMode, setFacingMode] = useState("user");
  const [imageName, setImageName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const webcamRef = React.useRef(null);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  };

  const handleRetake = () => {
    setImage(null);
  };

  const handleCameraSwitch = () => {
    setFacingMode(facingMode === "user" ? "environment" : "user");
  };

  const handleSave = () => {
    if (image) {
      // Convert the image data to a Blob
      const byteCharacters = atob(image.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });

      // Create a URL for the Blob
      const imageUrl = URL.createObjectURL(blob);

      // Trigger download by creating an anchor element
      const a = document.createElement("a");
      a.href = imageUrl;

      // Set the default file name based on user input
      const fileName = imageName || "captured_image.jpg";
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the Blob URL
      URL.revokeObjectURL(imageUrl);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Siders />
      <Layout>
        <Headers />
        <Content className="site-layout" style={{ padding: "0 10px" }}>
          <div className="webdfS">
            <h1>Take 3x4 Photo</h1>
            <div className="webcam-container">
              <div className="webcam-img">
                {image === null ? (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Webcam
                      audio={false}
                      height={400}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={330}
                      videoConstraints={{ ...videoConstraints, facingMode }}
                    />
                    {/* <canvas
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        border: "2px solid red", // Add a red border
                      }}
                      width="300" // Adjust the width of the rectangle
                      height="400" // Adjust the height of the rectangle
                    /> */}
                  </div>
                ) : (
                  <div className="sdghbadf">
                    <img src={image} alt="img" />
                    <div>
                      <Space>
                        <Button onClick={handleRetake}>Retake</Button>
                        <Button onClick={handleSave}>Save</Button>
                      </Space>
                      <br />
                      <Input
                        type="text"
                        size="large"
                        placeholder="Enter image name"
                        value={imageName}
                        onChange={(e) => setImageName(e.target.value)}
                      />
                      {/* <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.gif"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      /> */}
                    </div>
                  </div>
                )}
              </div>
              <div>
                {image === null && (
                  <Space>
                    <Button onClick={handleCameraSwitch} className="webcam-btn">
                      Switch Camera
                    </Button>
                    <Button onClick={handleCapture} className="webcam-btn">
                      Capture
                    </Button>
                  </Space>
                )}
              </div>
            </div>
          </div>
        </Content>
        <Footers />
      </Layout>
    </Layout>
  );
};

export default Webcamc;
