import {
  FallOutlined,
  RiseOutlined
} from "@ant-design/icons";
import { Card, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import CountUp from "react-countup";
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;
const App = () => {
  const [dataCount, setDataCount] = useState(0); // Add state to store the data count
  const [AllDrivers, setAllDrivers] = useState(0); // Add state to store the count of all drivers
  const [OnlyGuide, setOnlyGuide] = useState(0); // Add state to store the count of data with value "Tour Guide"
  const [numUser, setNumUser] = useState(0); // Add state to store the count of data from Staff.php
  const [numGirs, setNumGirs] = useState(0); // Add state to store the count of data from AddGrils.php

  const fetchData = async () => {
    try {
      const response1 = await axios.get(`${CONN_KEY}Add.php`);
      setDataCount(response1.data.length); // Set the data count based on the response
      const driverDataCount = response1.data.filter(
        (item) => item.vezife === "Driver"
      ).length;
      setAllDrivers(driverDataCount); // Set the count of all drivers based on the filtered response
      const guideDataCount = response1.data.filter(
        (item) => item.vezife === "Tour Guide"
      ).length;
      setOnlyGuide(guideDataCount); // Set the count of data with value "Tour Guide" based on the filtered response

      const response2 = await axios.get(`${CONN_KEY_SENAT}Staff.php`);
      setNumUser(response2.data.length); // Set the count of data from Staff.php based on the response

      const response3 = await axios.get(`${CONN_KEY_SENAT}AddGrils.php`);
      setNumGirs(response3.data.length); // Set the count of data from Girs.php based on the response
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const formatter = (value) => <CountUp end={value} separator="," />;

  const username = localStorage.getItem("username");
  const isAdmin = username === "zaurkhan" || username === "Asif";
  return (
    <div className="dashboard">
      {isAdmin && (
        <div className="asfsdfadsrfg">
          <Card bordered={false} className="cardStat">
            <Statistic
              title="Bələdçilər + Sürücü"
              value={dataCount} // Use the data count as the value
              formatter={formatter}
              precision={2}
              valueStyle={{
                color: dataCount >= 0 ? "#3f8600" : "#cf1322",
              }}
              prefix={dataCount >= 0 ? <RiseOutlined /> : <FallOutlined />}
            />
          </Card>
          {/* Bütün Sürücülər */}
          <Card bordered={false} className="cardStat">
            <Statistic
              title="Bütün Sürücülər"
              value={AllDrivers} // Use the count of all drivers as the value
              formatter={formatter}
              precision={2}
              valueStyle={{
                color: AllDrivers >= 0 ? "#3f8600" : "#cf1322",
              }}
              prefix={AllDrivers >= 0 ? <RiseOutlined /> : <FallOutlined />}
            />
          </Card>
          {/* Ümumi */}
          <Card bordered={false} className="cardStat">
            <Statistic
              title="Bələdçilər"
              value={OnlyGuide} // Use the count of data with value "Tour Guide" as the value
              formatter={formatter}
              precision={0}
              valueStyle={{
                color: AllDrivers >= 0 ? "#3f8600" : "#cf1322",
              }}
              prefix={AllDrivers >= 0 ? <RiseOutlined /> : <FallOutlined />}
            />
          </Card>
          {/* Staff sayı */}
          <Card bordered={false} className="cardStat">
            <Statistic
              title="Staff sayı"
              value={numUser} // Use the count of data from Staff.php as the value
              formatter={formatter}
              precision={0}
              valueStyle={{
                color: AllDrivers >= 0 ? "#3f8600" : "#cf1322",
              }}
              prefix={AllDrivers >= 0 ? <RiseOutlined /> : <FallOutlined />}
            />
          </Card>
          {/* Uzv sayı */}
          <Card bordered={false} className="cardStat">
            <Statistic
              title="WLK sayı"
              value={numGirs} // Use the count of data from Staff.php as the value
              formatter={formatter}
              precision={0}
              valueStyle={{
                color: AllDrivers >= 0 ? "#3f8600" : "#cf1322",
              }}
              prefix={AllDrivers >= 0 ? <RiseOutlined /> : <FallOutlined />}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default App;
