import { MoreOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Input, Layout, Menu, Modal, Space, Table, Tag, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Link, useNavigate } from "react-router-dom";
import { INITIAL_VALUE, TOOL_NONE } from "react-svg-pan-zoom";
import Footers from "../components/Footer";
import Headers from "../components/Header";
import MapM1ellips from "../components/Map/EllipsMap/MapEllipsM1";
import MapM2ellips from "../components/Map/EllipsMap/MapEllipsM2";
import MapM3ellips from "../components/Map/EllipsMap/MapEllipsM3";
import MapC from "../components/Map/Map";
import MapM2 from "../components/Map/MapM2";
import StatСancels from "../components/Map/StatCancel";
import StatMasa from "../components/Map/StatMasa.js";
import StatUSer from "../components/Map/StatUser.js";
import Tabbar from "../components/Tabbar";
import logo from "../images/logo.png";
const { Content } = Layout;
const CONN_KEY = process.env.REACT_APP_NOT_SECRET_CODE_R;
function Mapmain() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userLevel, setUserLevel] = useState('');
    const [open, setopen] = useState(false);
    const [isModalopen, setIsModalopen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedReason, setSelectedReason] = useState(''); // Define selectedReason state
    const mapM2Ref = useRef();
    const mapCRef = useRef();
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [tool, setTool] = useState(TOOL_NONE);
    const [value, setValue] = useState(INITIAL_VALUE);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const viewerRef = useRef();
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        // Fetch permissions from session storage
        const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
        console.log('Stored Permissions:', storedPermissions);

        if (storedPermissions && storedPermissions.length > 0) {
            setPermissions(storedPermissions[0]);
        }

        // Check if GuidesAzAdd permission is 0 and navigate if true
        if (storedPermissions && storedPermissions[0].Rezerv === 0) {
            navigate("/");
        }
    }, []);


    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 2000);
        return () => clearInterval(intervalId);
    }, []);
    // const showDrawer = () => {
    //     setopen(true);
    // };
    const onClose = () => {
        setopen(false);
    };
    const fetchData = () => {
        axios.get(`${CONN_KEY}printrezervs.php`)
            .then(response => {
                if (Array.isArray(response.data.reservations)) {
                    const updatedData = response.data.reservations.map(item => ({ ...item, key: item.id }));
                    setData(updatedData);
                    setIsLoading(false);
                } else {
                    console.error("Invalid data type or structure:", response.data);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    };
    const isNotTodayOrAfterEndDay = (record) => {
        const today = new Date();
        const date = new Date(record.created_at);

        // Assuming endDay is a property in the record
        const endDay = record.endDay;

        return today.toDateString() !== date.toDateString() || (endDay && today > new Date(endDay));
    };
    useEffect(() => {
        // Fetch user level from local storage
        const storedUserLevel = localStorage.getItem('s');
        if (storedUserLevel) {
            setUserLevel(storedUserLevel);
        }
    }, []);
    const rowClassName = (record) => {
        const isDisabled = isNotTodayOrAfterEndDay(record);

        return isDisabled ? "grayscale disabled" : "";
    };
    const navigate = useNavigate();

    const isNotToday = (dateString) => {
        const today = new Date();
        const date = new Date(dateString);

        return today.toDateString() !== date.toDateString();
    };
    const handlePermisson = (record) => {
        setSelectedRecord(record);
        setIsModalopen(true);
        setSelectedReason(''); // Reset selectedReason when opening the modal
    };
    const handleModalSubmit = async (sebeb) => {
        try {
            // Retrieve the username from local storage
            const username = localStorage.getItem('username');

            // Define the API endpoint
            const endpoint = `${CONN_KEY}permission.php`;

            // Create the request data object, including the selected record ID, reason, and username
            const requestData = {
                id: selectedRecord.id,
                sebeb: sebeb,
                Maneger: username, // Include the username as 'Maneger'
            };

            // Send the POST request using axios
            const response = await axios.post(endpoint, requestData);

            // Log the API response
            console.log("API Response:", response.data);

            // Close the modal and fetch updated data
            setIsModalopen(false);
            fetchData(); // Assuming fetchData() fetches the updated data
        } catch (error) {
            console.error("Error in handleModalSubmit:", error);
            // Handle error appropriately, e.g., show an error message to the user
        }
    };
    const canSeeSectionsRezervCancel = permissions.RezervCancel === 1;
    
    const renderActions = (text, record) => {

        if (
            record &&
            record.key
        ) {
            const menu = (
                <Menu>
                    {canSeeSectionsRezervCancel && (
                        <Menu.Item key="Permission" onClick={() => handlePermisson(record)}>Permission</Menu.Item>
                    )}

                    {/* <Menu.Item key="Details" onClick={() => showDrawer(record)}>
                        Details
                    </Menu.Item> */}
                </Menu>
            );

            return (
                <Dropdown overlay={menu} placement="bottomRight">
                    <Button>
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            );
        }

        return (
            <Button>
                <MoreOutlined />
            </Button>
        );
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndices) => {
        if (!Array.isArray(dataIndices)) {
            console.error("dataIndices is not an array:", dataIndices);
            return {}; // Return an empty object to prevent further errors
        }

        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndices.join(', ')}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndices)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndices)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) => {
                return dataIndices.some((dataIndex) => {
                    const columnValue = record[dataIndex];
                    return columnValue
                        ? columnValue.toString().toLowerCase().includes(value.toLowerCase())
                        : false;
                });
            },
            onFilterDropdownOpenChange: (open) => {
                if (open) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
            render: (text, record) => (
                dataIndices.map((dataIndex) => {
                    const textToHighlight = record[dataIndex]?.toString() || '';
                    return searchedColumn === dataIndex ? (
                        <Highlighter
                            key={dataIndex}
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={textToHighlight}
                        />
                    ) : (
                        <span>{textToHighlight}</span>
                    );
                })
            ),
        };
    };
    const columns = [
        {
            title: 'Name',
            dataIndex: ['username', 'surename'],  // Specify both 'username' and 'surname' fields as an array
            key: 'name',
            fixed: 'left',
            width: 150,
            ...getColumnSearchProps(['username', 'surename']),  // Pass the array of fields to search in
            render: (text, record) => (
                <a href={`/PDF/${record.userid}`}>
                    <p>{record.username}  {record.surename}</p>
                </a>
            ),
        },
        {
            title: 'Masa',
            dataIndex: 'Masa',
            key: 'Masa',
            width: 100,
            ...getColumnSearchProps(['Masa']),
            render: (text) => {
                // Remove leading '10', '20', or '30' if present |3|11
                const formattedText = text.replace(/^(10|20|30|)/, '');
                return formattedText;
            },
        },
        {
            title: 'M',
            dataIndex: 'selectedFlourId',
            key: 'adress',
            width: 100,
            render: (text) => {
                const prefixes = ["E1", "E2", "E3"];
                const startsWithPrefix = prefixes.some(prefix => text.startsWith(prefix));
                const containsPrefixAfterFirstChar = prefixes.some(prefix => text.slice(1).includes(prefix));

                if (startsWithPrefix && !containsPrefixAfterFirstChar) {
                    // Only one of "E1", "E2", "E3" at the beginning, render 'Ellips'
                    return (
                        <Tag color="green">
                            Ellips
                        </Tag>
                    );
                } else {
                    // Either not starting with "E1", "E2", "E3" or has "E1", "E2", "E3" after the first character, render 'Extra'
                    return (
                        <Tag color="yellow">
                            Extra
                        </Tag>
                    );
                }
            },
            width: 50,
        },
        {
            title: 'Mərtəbə Ərazi',
            dataIndex: 'selectedFlourId',
            key: 'selectedFlourId',
            ...getColumnSearchProps(['selectedFlourId']),
            width: 100,
        },
        {
            title: 'R Tarix / Saat',
            dataIndex: 'selectedDay',
            key: 'selectedDay',
            width: 100,
            render: (text, record) => {
                const date = new Date(text);
                return (
                    <div>
                        <p>{date.toLocaleDateString('en-GB')} {record.selectedTime}</p>
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (text) => (
                <Tag color={text === "pending" ? "yellow" : text === "complete" ? "green" : "red"}>
                    {text}
                </Tag>
            ),
        },
        {
            title: 'İmtina Səbəbi',
            dataIndex: 'sebeb',
            key: 'sebeb',
            width: 110,
        },
        {
            title: 'Maneger',
            dataIndex: 'Maneger',
            key: 'Manager',
            width: 68,
        },
        {
            title: 'Not',
            dataIndex: 'note',
            key: 'note',
            width: 100,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            width: 110,
            ...getColumnSearchProps(['selectedDay']),
            key: '75654',
            render: (text) => {
                // Convert the `created_at` data from a string to a Date object
                const date = new Date(text);

                // Use the toLocaleString method to format the date and time without milliseconds
                return date.toLocaleString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });
            },
        },
        {
            title: "More",
            dataIndex: "userid",
            key: "256321",
            render: renderActions,
            width: 50,
            fixed: 'right'
        },
    ];
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams((prevParams) => ({
            ...prevParams,
            pagination,
        }));
    };
    const handlePrintExtra = () => {
        // Open a new window
        const popupWindow = window.open('/printpage', '_blank', 'width=1200,height=900');

        // Check if the element with id 'printpage' exists
        const printPageElement = document.getElementById('printpage');
        if (printPageElement) {
            // Create a div for the printed content
            const printContainer = document.createElement('div');

            // Clone the content of the 'printpage' element
            printContainer.innerHTML = printPageElement.innerHTML;

            // Add the date information
            const printDateDiv = document.createElement('div');
            printDateDiv.innerHTML = `<p style="text-align: center; bottom:10px;">Printed on: ${new Date().toLocaleString()} By Marketing | VERSİON: 1.0.1</p>`;
            printContainer.appendChild(printDateDiv);

            // Replace 'printContents' with 'originalContents'
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContainer.innerHTML;

            // Print the new content
            popupWindow.print();

            // Restore the original content
            document.body.innerHTML = originalContents;

            // Close the popup window
            popupWindow.document.close();
        }
    };
    const handlePrintEllips = () => {
        // Open a new window
        const popupWindow = window.open('/printpageEllips', '_blank', 'width=1200,height=900');

        // Check if the element with id 'printpage' exists
        const printPageElement = document.getElementById('printpage');
        if (printPageElement) {
            // Create a div for the printed content
            const printContainer = document.createElement('div');

            // Clone the content of the 'printpage' element
            printContainer.innerHTML = printPageElement.innerHTML;

            // Add the date information
            const printDateDiv = document.createElement('div');
            printDateDiv.innerHTML = `<p style="text-align: center; bottom:10px;">Printed on: ${new Date().toLocaleString()} By Marketing | VERSİON: 1.0.1</p>`;
            printContainer.appendChild(printDateDiv);

            // Replace 'printContents' with 'originalContents'
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContainer.innerHTML;

            // Print the new content
            popupWindow.print();

            // Restore the original content
            document.body.innerHTML = originalContents;

            // Close the popup window
            popupWindow.document.close();
        }
    };
    const fetchDataInComponents = () => {
        window.location.reload();
    };
    return (
        <Layout >
            <Header className="inset-x-0 top-0 z-50 bg-[#000910] flex flex-row flex-nowrap justify-between items-center">
                <Link to={"/Main"}>
                    <img
                        className="h-8 w-auto cursor-pointer"
                        src={logo}
                        alt="logo"
                    />
                </Link>
                <div className="flex items-center gap-5">
                    <Button className="bg-blue-50 p-2" onClick={fetchDataInComponents}>
                        <SyncOutlined spin />
                    </Button>
                    <Button className="bg-blue-50 p-2" >
                        <a href={`/3d`}> 3D</a>
                    </Button>
                    <Button className="buttonprint bg-blue-50" target="_blank" onClick={handlePrintExtra}>
                        Print Extra
                    </Button>
                    <Button className="buttonprint bg-blue-50" target="_blank" onClick={handlePrintEllips}>
                        Print Ellips
                    </Button>
                    <Headers />
                </div>
            </Header>
            <Content className="site-layout ">
                <div style={{ padding: 24, minHeight: 380, background: colorBgContainer }}>
                    <div className='infosds'>
                        <div className='ckdj'>
                            <div className="redbox" />-
                            <p style={{ fontSize: '16px' }}>Rezerv edilib</p>
                        </div>
                        <div className='ckdj'>
                            <div className="greenbox" />-
                            <p style={{ fontSize: '16px' }}>Imtina edilib</p>
                        </div>
                        <div className='ckdj'>
                            <div className="greenboxBorder" />-
                            <p style={{ fontSize: '16px' }}>Boş Masalar</p>
                        </div>
                        <StatUSer />
                        <StatСancels />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'flex-start', flexDirection: 'row', gap: '10px' }}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            loading={isLoading}
                            sticky
                            rowClassName={rowClassName}
                            onChange={handleTableChange}
                            style={{ maxWidth: '1200px', width: '100%' }}
                            scroll={{ y: 620, x: 'max-content' }}
                            pagination={{
                                ...tableParams.pagination,
                                showSizeChanger: true,
                                showTotal: (total) => `Total ${total} items`,
                                pageSizeOptions: ['5', '10', '20', '30'],
                            }}
                        />
                    </div>
                    <Tabbar />
                    <Drawer
                        title="Basic Drawer"
                        placement="right"
                        onClose={onClose}
                        open={open}
                    >
                    </Drawer>
                    <Modal
                        title="Permission"
                        open={isModalopen}
                        onOk={() => handleModalSubmit(selectedReason)} // Remove 'rejected' argument
                        onCancel={() => setIsModalopen(false)}
                        destroyOnClose
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        okText="İmtina et"
                        cancelText="Cancel"
                    >
                        <p>İstifadəçiyə ləğvetmə icazəsi vermək istədiyinizə əminsiniz <b>{selectedRecord?.username} {selectedRecord?.surename} {selectedRecord?.Masa}</b>?</p>
                        <Input
                            type="text"
                            value={selectedReason}
                            rules={[
                                {
                                    required: true,
                                    message: "Zəhmət olmasa imtina səbəbini qeyd edin!",
                                },
                            ]}
                            placeholder="İmtina səbəbini qeyd edin"
                            onChange={(e) => setSelectedReason(e.target.value)}
                        />
                    </Modal>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            gap: '10px'
                        }}>
                        {/* <AutoSizer>
                            {({ height, width }) => (
                                <ReactSVGPanZoom
                                    width={width}
                                    height={height}
                                    ref={viewerRef}
                                    tool={tool}
                                    onChangeTool={setTool}
                                    value={value}
                                    onChangeValue={setValue}
                                    background="#FFF"
                                    detectAutoPan={false}
                                > */}
                        <MapC />
                        <MapM2 />
                        <MapM1ellips />
                        <MapM2ellips />
                        <MapM3ellips />
                        {/* </ReactSVGPanZoom>
                            )}
                        </AutoSizer> */}
                    </div>
                    <StatMasa />
                </div>
            </Content>
            <Footers />
        </Layout>
    );
};
export default Mapmain;