import {
    DeleteOutlined,
    EditOutlined,
    FilePdfOutlined,
    IdcardOutlined,
    LogoutOutlined,
    MoreOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {
    Badge,
    Button,
    Checkbox,
    Dropdown,
    Empty,
    Form,
    Image,
    Input,
    Menu,
    message,
    Modal,
    Select,
    Skeleton,
    Space,
    Table,
    Tag
} from "antd";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link, useNavigate } from "react-router-dom";
const { Option } = Select;

const CONN_KEY_SENAT = process.env.REACT_APP_NOT_SECRET_CODE_R;

function StafTable() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isModalopen, setIsModalopen] = useState(false);
  const [staffOutReason, setStaffOutReason] = useState("");
  const [staffOutDate, setStaffOutDate] = useState("");
  const staffOutReasonInputRef = useRef(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [statusFilter, setStatusFilter] = useState("green");
  const [dropdownValues, setDropdownValues] = useState([]);
  const [dropdownValuesVezife, setDropdownValuesVezife] = useState([]);
  const [permissions, setPermissions] = useState({});
  const filterDropdown = (values) => { };

  const navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn =
      localStorage.getItem("username") !== null;
    if (!isLoggedIn) {
      navigate("/");
    } else {
      console.log("User is logged in.");
    }
  }, [navigate]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 15,
    },
  });
  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  const fetchData = () => {
    axios
      .get(CONN_KEY_SENAT + "Staff.php")
      .then((response) => {
        // Add a unique "key" prop to each item
        const updatedData = response.data.map((item) => ({
          ...item,
          key: item.id,
        }));
        setData(updatedData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            confirm();
          }
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={confirm}
          style={{
            width: 188,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownopenChange: (open) => {
      if (open) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    axios
      .get(CONN_KEY_SENAT + "Staff.php")
      .then((response) => {
        // Extract custom job titles from each staff member
        const vezifeValues = response.data.map((item) => item.vezife);

        // Remove duplicate job titles using Set
        const uniqueVezifeValues = [...new Set(vezifeValues)];

        // Update dropdown values
        setDropdownValuesVezife(uniqueVezifeValues);

        // Call the filterDropdown function passing the dropdown values as an argument
        filterDropdown(uniqueVezifeValues);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);
  const getColumnVezifeFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "vezife" && (
          <Select
            ref={searchInput}
            placeholder={`Filter by ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            {dropdownValuesVezife.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        )}
        <Space>
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) => {
      return record[dataIndex] === value;
    },
    render: (text) => {
      const tagColor = text === "General Manager" ? "red" : "green";
      return <>{text.toUpperCase()}</>;
    },
  });
  useEffect(() => {
    axios
      .get(CONN_KEY_SENAT + "Staff.php")
      .then((response) => {
        // Extract custom job titles from each staff member
        const DepartamentValues = response.data.map((item) => item.Departament);

        // Remove duplicate job titles using Set
        const uniqueDepartamentValues = [...new Set(DepartamentValues)];

        // Display unique job titles
        // console.log(uniqueDepartamentValues);

        // Update dropdown values
        setDropdownValues(uniqueDepartamentValues);

        // Call the filterDropdown function passing the dropdown values as an argument
        filterDropdown(uniqueDepartamentValues);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);
  const getColumnFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "Departament" && (
          <Select
            ref={searchInput}
            placeholder={`Filter by ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            {dropdownValues.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        )}
        <Space className="flex ">
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, backgroundColor: '#1677ff ' }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) => {
      if (dataIndex === "Departament") {
        return record[dataIndex] === value;
      }
      return record[dataIndex] === value;
    },
    render: (text) => {
      const tagColor = text === "General Manager" ? "red" : "green";
      return <Tag color={tagColor}>{text.toUpperCase()}</Tag>;
    },
  });
  const getColumnSWorkProps = (dataIndex) => {
    const dropdownValues = ["Ellips", "Extra", "Sahara", "Georgia"];
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            ref={searchInput}
            allowClear
            placeholder={`Filter by ${dataIndex}`}
            value={selectedKeys[0]} // Access the first selected key
            onChange={(value) => setSelectedKeys(value ? [value] : [])} // Wrap single value in an array
            onSearch={(value) => { }}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            {dropdownValues.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const dataIndexValue = record[dataIndex];
        return (
          dataIndexValue &&
          value &&
          dataIndexValue.toUpperCase() === value.toUpperCase()
        );
      },
      render: (text, record) => {
        let tagColor;
        if (record && record.Status) {
          // Use slightly darker pale colors if the status is true
          switch (text) {
            case "Extra":
              tagColor = "#f3e0a7"; // Slightly darker pale yellow
              break;
            case "Ellips":
              tagColor = "#edc4f3"; // Slightly darker pale purple
              break;
            case "Sahara":
              tagColor = "#c5ddf2"; // Slightly darker pale blue
              break;
            case "Georgia":
              tagColor = "#749bd9"; // Slightly darker pale blue
              break;
            default:
              tagColor = "#d0d0d0"; // Slightly darker pale gray
              break;
          }
        } else {
          // Use regular colors if the status is false
          switch (text) {
            case "Extra":
              tagColor = "#f7c66e"; // Yellow
              break;
            case "Ellips":
              tagColor = "#ffa5ff"; // Purple
              break;
            case "Sahara":
              tagColor = "#7fb3db"; // Blue
              break;
            case "Georgia":
              tagColor = "#749bd9"; // Blue
              break;
            default:
              tagColor = "green"; // Green
              break;
          }
        }
        return <Tag color={tagColor}>{text.toUpperCase()}</Tag>;
      },
    };
  };
  const handleOut = (record) => {
    setSelectedRecord(record);
    setStaffOutReason("");
    setStaffOutDate("");
    setIsModalopen(true);
  };
  const handleModalCancel = () => {
    setIsModalopen(false);
  };
  const handleModalSubmit = async () => {
    try {
      const response = await axios.post(`${CONN_KEY_SENAT}StaffOut.php`, {
        id: selectedRecord.id,
        outwhy: staffOutReason,
        outdate: staffOutDate,
      });
      console.log(response.data);
      setIsModalopen(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handlePostToGeo = (id, userid, Fin) => {
    const data = new FormData();
    data.append("id", id);
    data.append("userid", userid);
    data.append("Fin", Fin);

    const url = `${CONN_KEY_SENAT}ToGeo.php?id=${id}`;

    axios.post(url, data)
      .then((response) => {
        console.log("Response from server:", response.data);

        // Check the status in the response
        if (response.data.status === 1) {
          // If the status is 1, show a success alert
          message.success(`${response.data.message}`);
        } else {
          // If the status is 0, show an error alert with the message
          message.error(`Error: ${response.data.message}`);
        }
      })
      .catch((error) => {
        console.error("Error in Axios request:", error);
        message.error('An error occurred while making the request.');
      });
  }
  useEffect(() => {
    // Fetch permissions from session storage
    const storedPermissions = JSON.parse(localStorage.getItem('permissions'));

    if (storedPermissions && storedPermissions.length > 0) {
      setPermissions(storedPermissions[0]);
    }
  }, []);

  const renderActions = (text, record) => {
    const canSeeSectionsStafAzEdit = permissions.StafAzEdit === 1;
    const canSeeSectionsStafAzOutWork = permissions.StafAzOutWork === 1;
    const canSeeSectionsStafAzDelet = permissions.StafAzDelet === 1;

    const menu = (
      <Menu>
        {canSeeSectionsStafAzEdit && (
          <Menu.Item key="edit">
            <Link to={`/UpdateStaff/${record.id}`}>
              <EditOutlined /> Edit
            </Link>
          </Menu.Item>
        )}
        {canSeeSectionsStafAzOutWork && (
          <Menu.Item key="Out" onClick={() => handleOut(record)}>
            <LogoutOutlined /> İşdən çıxmaq
          </Menu.Item>
        )}
        {canSeeSectionsStafAzDelet && (
          <Menu.Item key="delete" onClick={() => handleDelete(record)}>
            <DeleteOutlined /> Delete
          </Menu.Item>
        )}
        {canSeeSectionsStafAzEdit && record && record.key && (
          <Menu.Item key="postToGeo">
            <Button onClick={() => handlePostToGeo(record.id, record.userid, record.Fin)}>
              Post to Geo
            </Button>
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <Space>
        <Dropdown overlay={menu} placement="bottomRight">
          <Button>
            <MoreOutlined />
          </Button>
        </Dropdown>
      </Space>
    );
  };

  const columns = [
    {
      title: "3x4",
      dataIndex: "filePP",
      key: "1",
      width: 100,
      render: (imagePath, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image
            className={`imgpp ${record.Status === '1' || record.Status === 'Geo' ? "grayscale" : ""}`}
            src={`${CONN_KEY_SENAT}/Staff/3x4Staff/${imagePath}`}
            alt="filePP"
          />
        </div>
      ),
    },
    {
      title: "Vəsiqə nömresi",
      dataIndex: "userid",
      key: "1",
      width: 150,
      sorter: {
        compare: (a, b) => b.userid.localeCompare(a.userid),
        multiple: 1,
      },
      render: (value, record) => {
        const { Catagory } = record; // Assuming `Catagory` is available in the `record` object
        return (
          <>
            <h3 className="bold">
              {value} {record.Catagory}{" "}
              {/* Updated: Accessing 'Catagory' directly from 'record' */}
            </h3>
          </>
        );
      },
      ...getColumnSearchProps("userid"), // Assuming `getColumnSearchProps` is defined elsewhere
    },
    {
      title: "İstifadeci adı",
      dataIndex: "username",
      width: 150,
      key: "24",
      ...getColumnSearchProps("username"),
    },
    {
      title: "Soy ad",
      dataIndex: "surename",
      width: 150,
      key: "256",
      ...getColumnSearchProps("surename"),
    },
    {
      title: "Ata adı",
      dataIndex: "dadname",
      width: 150,
      key: "23254",
      ...getColumnSearchProps("dadname"),
    },
    {
      title: "Iş yeri",
      dataIndex: "lokationW",
      width: 110,
      key: "lokationW",
      ...getColumnSWorkProps("lokationW"), // Add this line to enable the filter
    },
    {
      title: "Fin",
      dataIndex: "Fin",
      width: 100,
      key: "756",
      ...getColumnSearchProps("Fin"),
    },
    {
      title: "Telefon",
      dataIndex: "Phone",
      width: 150,
      key: "2sd4",
      ...getColumnSearchProps("Phone"),
      render: (Phone) => {
        return Phone !== 'undefined' && Phone !== null && Phone.trim() !== '' ? Phone : <Badge status="processing" text="Müəyyən edilməmiş" />;
      },
    },
    {
      title: "Vəzifəsi",
      dataIndex: "vezife",
      width: 150,
      key: "3",
      ...getColumnVezifeFilterProps("vezife"),
    },
    {
      title: "Departament",
      dataIndex: "Departament",
      width: 150,
      key: "75654",
      ...getColumnFilterProps("Departament"),
    },
    {
      title: "Akkreditasiya",
      dataIndex: "Catagory",
      width: 120,
      key: "75654",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "9",
      width: 95,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Checkbox
            checked={selectedKeys.includes("1")}
            onChange={() => {
              setSelectedKeys(selectedKeys.includes("1") ? [] : ["1"]);
              confirm();
              setStatusFilter("1");
            }}
          >
            İşləmir
          </Checkbox>
          <Checkbox
            checked={selectedKeys.includes("2")}
            onChange={() => {
              setSelectedKeys(selectedKeys.includes("2") ? [] : ["2"]);
              confirm();
              setStatusFilter("2");
            }}
          >
            İşləyir
          </Checkbox>
          <Checkbox
            checked={selectedKeys.includes("Geo")}
            onChange={() => {
              setSelectedKeys(selectedKeys.includes("Geo") ? [] : ["Geo"]);
              confirm();
              setStatusFilter("Geo");
            }}
          >
            Georgia
          </Checkbox>
          <div style={{ marginTop: 8 }} className="flex flex-wrap">
            <Button
              type="primary"
              size="small"
              onClick={() => {
                confirm();
                setStatusFilter("");
              }}
              style={{ marginRight: 8 }}
            >
              Filter
            </Button>
            <Button size="small" onClick={clearFilters}>
              Reset
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => {
        if (statusFilter === null || statusFilter === "") {
          return true;
        }
        return record.Status ? record.Status === value : false;
      },
      render: (text) => (
        <Tag color={text === "1" ? "red" : text === "2" ? "green" : "blue"}>
          {text === "1" ? "İşləmir" : text === "2" ? "İşləyir" : "Georgia"}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "userid",
      key: "x",
      width: 100,
      render: (text, record) => (
        <Space>
          <Link
            to={`/CardPersonal/${encodeURIComponent(record.id)}`}
            target="_blank"
          >
            <Button className={`ColorMain ${record.Status === '1' || record.Status === 'Geo' ? "ccolorblock" : ""}`} icon={<IdcardOutlined />}>
              {/* Cart */}
            </Button>
          </Link>
          <Link to={`/PDFStaff/${record.id}`} target="_blank">
            <Button className={`ColorMain ${record.Status === '1' || record.Status === 'Geo' ? "ccolorblock" : ""}`} icon={<FilePdfOutlined />}>
              {/* Cart */}
            </Button>
          </Link>
        </Space>
      ),
    },
    {
      title: "More",
      dataIndex: "userid",
      key: "256321",
      render: renderActions,
      width: 80, // Set minimum width to 100 pixels
    },
  ];
  const handleDelete = (record) => {
    Modal.confirm({
      title: "Diqqət",
      content:
        "Bu Bələdçini silmək istədiyinizə əminsiniz? Bu əməliyyat geri qaytarıla bilməz.",
      okText: "Sil",
      okType: "danger",
      cancelText: "Ləğv et",
      onOk: async () => {
        try {
          const response = await axios.delete(
            `${CONN_KEY_SENAT}Staff.php?userid=${record.userid}`
          );
          console.log(response.data); // You can log the response data to see the server's response
          // Handle success, e.g., update the table or show a success message
          // Update the table or perform any necessary actions
        } catch (error) {
          // Handle error, e.g., show an error message
          console.error(error);
        }
      },
    });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // handle table change event and update the state if necessary
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination,
    }));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : data.length > 0 ? (
        <>
          <Modal
            title="İşdən çıxmaq"
            open={isModalopen}
            onOk={handleModalSubmit}
            onCancel={handleModalCancel}
            destroyOnClose
          >
            <Form>
              <Form.Item
                label="Səbəb"
                name="outwhy"
                rules={[
                  {
                    required: true,
                    message: "Please input Release Reason!",
                  },
                ]}
              >
                <Input
                  placeholder="Səbəb"
                  value={staffOutReason}
                  onChange={(e) => setStaffOutReason(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Cıxma tarixi"
                name="outdate"
                rules={[
                  {
                    required: true,
                    message: "Please input Release date!",
                  },
                ]}
              >
                <Input
                  placeholder="Cıxma tarixi"
                  value={staffOutDate}
                  onChange={(e) => setStaffOutDate(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Modal>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            onChange={handleTableChange}
            scroll={{ y: 580, x: "max-content" }}
            summary={() => <Table.Summary fixed="top"></Table.Summary>}
            pagination={{
              ...tableParams.pagination,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: [
                "5",
                "10",
                "20",
                "30",
                "50",
                "100",
                "200",
                "500",
                "800",
              ],
            }}
            locale={{
              emptyText: (
                <Empty
                  description="No Data"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ marginTop: 50 }}
                />
              ),
            }}
            rowClassName={(record) => (record.Status === '1' || record.Status === 'Geo' ? 'disabled' : '1')}
          />
        </>
      ) : (
        <div>
          <Skeleton active />
        </div>
      )}
    </>
  );
}

export default StafTable;
